import { Attribute, HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import type {
  Attempt,
  Course,
  Enrollment,
  Exemption,
  Extension,
  Question,
  Role, RoleName,
  Teaching,
} from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'users',
})
export class User extends Model {
  @Attribute() email: string;
  @Attribute() firstName: string;
  @Attribute() lastName: string;
  @Attribute() displayName: string;
  @Attribute() createdAt: Date;
  @Attribute() adminPanelPermitted: boolean;
  @Attribute() flatworldId: number;
  @Attribute() uuid: string;
  @Attribute() intercomHash: string;

  @HasMany() enrolledCourses: Course[];
  @HasMany() taughtCourses: Course[];

  @HasMany() enrollments: Enrollment[];
  @HasMany() teachings: Teaching[];
  @HasMany() roles: Role[];

  @HasMany() attempts: Attempt[];
  @HasMany() extensions: Extension[];
  @HasMany() exemptions: Exemption[];

  @HasMany() authoredQuestions: Question[];

  get displayEmail(): string {
    const displayName = this.displayName || '';
    const email = this.email ? `<${this.email}>` : '';
    const separator = (displayName && email) ? ' ' : '';
    return `${displayName}${separator}${email}`;
  }

  get hasTaughtCourses(): boolean {
    return !_.isEmpty(this.taughtCourses);
  }

  get hasEnrolledCourses(): boolean {
    return !_.isEmpty(this.enrolledCourses);
  }

  get hasExemptions(): boolean {
    return !_.isEmpty(this.exemptions);
  }

  get hasCourseExemptions(): boolean {
    if (!this.hasExemptions) { return false; }
    return this.exemptions.some(e => !e.assignment);
  }

  get isFromLti(): boolean {
    return _.some(_.flatMap([this.enrolledCourses, this.taughtCourses]), 'isFromLti');
  }

  courseExemption(course: Course): Exemption {
    if (!course || !this.hasCourseExemptions) { return; }
    return this.exemptions.find(e => !e.assignment && (course.id === e.course?.id));
  }

  isEditorialPermitted(): boolean {
    return this.hasRole('admin', 'editor', 'collaborator');
  }

  isRoleManagementPermitted(): boolean {
    return this.hasRole('admin', 'editor');
  }

  hasRole(...roles: RoleName[]): boolean {
    return _.some(roles, (role) => _.some(this.roles, { name: role }));
  }

  isEducator(course: Course): boolean {
    return _.includes(_.map(this.taughtCourses, 'id'), course?.id);
  }

  isStudent(course: Course): boolean {
    return _.includes(_.map(this.enrolledCourses, 'id'), course?.id);
  }

  isAdmin(): boolean {
    return this.hasRole('admin');
  }
}
