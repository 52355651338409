import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { User } from '../../model';
import { BaseAuthDirective } from './base-auth.directive';

@Directive()
export abstract class BaseUserDirective extends BaseAuthDirective {

  constructor(
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    authService: AuthService,
  ) {
    super(templateRef, viewContainer, authService);
  }

  protected canBeViewed(authService: AuthService): Observable<boolean> {
    return authService.currentUser
      .pipe(
        map((user: User) => {
          if (!user) {
            return false;
          }
          return this.canBeViewedBy(user);
        }),
        distinctUntilChanged(),
      );
  }

  protected abstract canBeViewedBy(user: User): boolean;
}
