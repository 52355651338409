import { Injectable } from '@angular/core';

export enum Environment {
  Local = 'local',
  Staging = 'staging',
  Production = 'production',
}

@Injectable()
export class EnvService {
  private _env: Environment;

  get env(): Environment {
    return this._env;
  }

  get isProduction(): boolean {
    return this.env === Environment.Production;
  }

  constructor() {
    this.setEnv();
  }

  private setEnv() {
    const hostname = window?.location?.hostname;

    if (/^practice\.flatworldknowledge\.com$/.test(hostname)) {
      this._env = Environment.Production;
    } else if (/\.flatworldknowledgestage\.com$/.test(hostname)) {
      this._env = Environment.Staging;
    } else if (/^(practice\.flatworldknowledgelocal.com|localhost|127.0.0.1)$/.test(hostname)) {
      this._env = Environment.Local;
    } else {
      console.warn(`No matching environment for hostname: ${hostname}`);
    }
  }
}
