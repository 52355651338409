import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationService } from './authentication/location.service';
import { QueryParams } from './constants';

export type ApplicationNavigationType = 'embedded' | 'normal';

@Injectable()
export class ApplicationNavigationTypeService {
  private _navigationType: Observable<ApplicationNavigationType>;
  private _isInLtiAttempt: Observable<boolean>;
  private _hideAssignmentNav: Observable<boolean>;

  constructor(private location: LocationService) {
    const url = this.location.href;

    this._navigationType = this.pluck(url, QueryParams.EMBEDDED_NAVIGATION)
      .pipe(map((value) => value ? 'embedded' : 'normal'));
    this._isInLtiAttempt = this.pluck(url, QueryParams.LTI_ATTEMPT)
      .pipe(map((value) => !!value));
    this._hideAssignmentNav = this.pluck(url, QueryParams.HIDE_ASSIGNMENT_NAV)
      .pipe(map((value) => !!value));
  }

  public get navigationType(): Observable<ApplicationNavigationType> {
    return this._navigationType;
  }

  public get isInLtiAttempt(): Observable<boolean> {
    return this._isInLtiAttempt;
  }

  public get shouldHideAssignmentNav(): Observable<boolean> {
    return this._hideAssignmentNav;
  }

  private pluck(fullUrl: string, name: string): Observable<string> {
    return of(fullUrl).pipe(map(url => {
      const urlObject = new URL(url);
      const params = new URLSearchParams(urlObject.search);
      const jsonParams = this.parseParams(params);

      return params.get(name) || (jsonParams && jsonParams[name]);
    }));
  }

  private parseParams(rawParams: URLSearchParams): {} {
    const params = rawParams.get('params');

    try {
      return JSON.parse(params);
    } catch {
      return { raw_params: params };
    }
  }
}
