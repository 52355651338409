import { Attribute, BelongsTo, HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import type { Book, Question } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'chapters',
})
export class Chapter extends Model {
  @Attribute() name: string;
  @Attribute() createdAt: Date;
  @Attribute() updatedAt: Date;
  @Attribute() ordinal: number;
  @Attribute() ordinalName: string;
  @Attribute() questionsCount: number;
  @Attribute() childrenCount: number;
  @Attribute() examQuestionsCount: number;
  @Attribute() assignmentQuestionsCount: number;

  @BelongsTo() book: Book;
  @HasMany() questions: Question[];
  @BelongsTo() parent: Chapter;
  @HasMany() children: Chapter[];

  get deepOrdinal(): string {
    if (!this.parent) {
      return `${this.ordinal}`;
    }
    return `${this.parent.deepOrdinal}.${this.ordinal}`;
  }

  get deepOrdinalSortValue(): string {
    // Return left-padded deep ordinal for proper sorting
    // (e.g. to ensure 1.10.1 sorts *after* 1.9.1)
    return _.chain(this.deepOrdinal)
      .split('.')
      .map(o => _.padStart(o, 3, '0'))
      .join('.')
      .value();
  }

  get nameWithOrdinal(): string {
    return `${this.deepOrdinal}. ${this.name}`;
  }
}
