import { ActivatedRoute, ActivatedRouteSnapshot, Data } from '@angular/router';
import _ from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';

// An auxiliary interface to make the type system happy, as there isn't a built-in interface for
// routes/snapshots with parents.
interface IChildRoute {
  parent: any;
}

export abstract class RouteTraversal {
  public static traverseSnapshotParams(route: ActivatedRouteSnapshot, key: string) {
    return RouteTraversal.traverseUpwards(route, (currentRoute) => {
      if (!currentRoute.paramMap) {
        return undefined;
      }

      return currentRoute.paramMap.get(key);
    });
  }

  public static traverseSnapshotData(route: ActivatedRouteSnapshot, key: string) {
    return RouteTraversal.traverseUpwards(route, (currentRoute) => {
      if (!currentRoute.data) {
        return undefined;
      }

      return currentRoute.data[key];
    });
  }

  public static traverseRouteData(route: ActivatedRoute, key: string): Observable<any> {
    return RouteTraversal.traverseUpwardsAsync(route,
      (currentRoute) => {
        if (!currentRoute || !currentRoute.data) {
          return undefined;
        }

        return currentRoute.data;
      },
      (data: Data) => data[key],
    );
  }

  private static traverseUpwards<T extends IChildRoute>(route: T,
                                                        retriever: (route: T) => any) {
    while (!!route) {
      const parameterValue = retriever(route);
      if (!_.isNil(parameterValue)) {
        return parameterValue;
      }
      route = route.parent;
    }

    return null;
  }

  private static traverseUpwardsAsync<T extends IChildRoute, D>(route: T,
                                                                dataRetriever: (route: T) => Observable<D>,
                                                                retriever: (data: D) => any): Observable<any> {
    const resultSubject: Subject<any> = new ReplaySubject();

    const traverse = (currentRoute: T) => {
      const dataObservable: Observable<D> = dataRetriever(currentRoute);
      if (_.isNil(dataObservable)) {
        resultSubject.next(null);
        return;
      }

      dataObservable.subscribe((data: D) => {
        const value = retriever(data);

        if (_.isNil(value)) {
          traverse(currentRoute.parent);
          return;
        }

        resultSubject.next(value);
      });
    };

    traverse(route);

    return resultSubject;
  }
}
