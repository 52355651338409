import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { JsonApiModule } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppComponent } from './app.component';
import { ApplicationNavigationTypeService } from './application-navigation-type.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { IDestinationOptions } from './authentication/return-options';
import { ChapterService } from './chapter.service';
import { ClipboardService } from './clipboard.service';
import { CookieConsentWidgetComponent } from './cookie-consent-widget/cookie-consent-widget.component';
import { Urls } from './constants';
import { BreadcrumbModule } from './custom-components/breadcrumb.module';
import { DigitalClockModule } from './custom-components/digital-clock.module';
import { LoadingIndicatorModule } from './custom-components/loading-indicator.module';
import { SnackbarModule } from './custom-components/snackbar.module';
import { Datastore } from './datastore/datastore.service';
import { AvailableHintsDialogComponent } from './editorial/available-hints-dialog/available-hints-dialog.component';
import { QuestionCreateDialogComponent } from './editorial/question-create-dialog/question-create-dialog.component';
import { EmbeddedResolve } from './embedded-resolve';
import { EnvService } from './env.service';
import { FlatworldMaterialModule } from './flatworld-material/flatworld.material.module';
import { IntercomCredentialsService, IntercomWidgetComponent } from './intercom-widget/intercom-widget.component';
import { AppLoadingWatch } from './loading/app-loading-watch';
import { HttpTrafficWatch } from './loading/http-traffic-watch';
import { LoadingWatch } from './loading/loading-watch';
import { NavigationWatch } from './loading/navigation-watch';
import { LogrocketWidgetComponent } from './logrocket-widget/logrocket-widget.component';
import { LogRocketService } from './logrocket-widget/logrocket.service';
import { MathJaxModule } from './mathjax/mathjax.module';
import { Course, User } from './model';
import { ModelInvalidationService } from './model-invalidation.service';
import { AssignmentNamer, BookNamer, ChapterNamer, CourseNamer, LtiCourseNamer, QuestionNamer } from './namers';
import { AirbrakeCredentialsService, AirbrakeErrorHandler } from './navigation/airbrake-error-handler';
import { NavigationModule } from './navigation/navigation.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaginationService } from './pagination.service';
import { PipesModule } from './pipes/pipes.module';
import { PromptersModule } from './prompters/prompters.module';
import { ScrollService } from './scroll.service';
import { ServerErrorInterceptor } from './server-error-handling/server-error-interceptor';
import { ServerErrorComponent } from './server-error/server-error.component';
import { SessionStorageService } from './session-storage.service';
import { SurveyService } from './survey-widget/survey.service';
import { TitleService } from './title.service';
import { Notifier } from './prompters/notifications';

const LEARNOSITY_SCRIPT_SOURCES = [
  `https://items.learnosity.com/?${Urls.LEARNOSITY_VERSION}`,
  `https://reports.learnosity.com/?${Urls.LEARNOSITY_VERSION}`,
  `https://questioneditor.learnosity.com/?${Urls.LEARNOSITY_VERSION}`,
  `https://questions.learnosity.com/?${Urls.LEARNOSITY_VERSION}`,
  `https://authorapi.learnosity.com/?${Urls.LEARNOSITY_VERSION}`,
];

const insertLearnosityScripts = (): Promise<boolean> => (
  new Promise<boolean>((resolve, reject) => {
    let loadedScripts = 0;

    LEARNOSITY_SCRIPT_SOURCES.forEach(source => {
      const scriptEl = document.createElement('script');
      scriptEl.src = source;

      scriptEl.onload = () => {
        // Resolve only when all Learnosity scripts have loaded
        if (++loadedScripts === LEARNOSITY_SCRIPT_SOURCES.length) {
          resolve(true);
        }
      };

      scriptEl.onerror = () => {
        // Reject if any Learnosity script fails to load
        reject(`${source} failed to load`);
      };

      document.head.appendChild(scriptEl);
    });
  })
);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/courses',
  },
  {
    path: 'auth',
    loadChildren: () => import('app/authentication/authentication-routing.module').then(m => m.AuthenticationRoutingModule),
    data: {
      hideAllBreadcrumbs: true,
    },
  },
  {
    path: 'lti-course',
    loadChildren: () => import('app/lti-course/lti-course.module').then(m => m.LtiCourseModule),
  },
  {
    path: 'courses',
    loadChildren: () => import('app/courses/courses.module').then(m => m.CoursesRoutingModule),
    data: {
      breadcrumb: 'Courses',
    },
  },
  {
    path: 'assignments',
    loadChildren: () => import('app/assignments/assignments.module').then(m => m.AssignmentsRoutingModule),
    data: {
      breadcrumb: 'Assignments',
    },
  },
  {
    path: 'editorial',
    loadChildren: () => import('app/editorial/editorial.module').then(m => m.EditorialModule),
  },
  {
    path: 'c',
    loadChildren: () => import('app/invitations/invitations.module').then(m => m.InvitationsModule),
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

export const navigateToLoginPage = (returnPath?: string) => {
  const targetPath = returnPath?.length > 0 ? returnPath : window.location.href;
  const encoded = encodeURIComponent(targetPath);

  // We'll use replace to avoid stacking new stuff in the browser history. The fun thing is that we
  // can't just replace the location with a partial URL, as we may end up with a completely broken
  // URL (e.g. wrong protocol). So we'll construct the whole thing manually.
  const loginUrl = `${Urls.LOGIN}?destination=${encoded}`;
  const protocol = window.location.protocol;
  const host = window.location.host;
  window.location.replace(`${protocol}//${host}${loginUrl}`);
};

export const navigateToSignOutPage = (sourcePath?: string) => {
  const targetPath = sourcePath?.length > 0 ? sourcePath : window.location.href;
  const encoded = encodeURIComponent(targetPath);

  // The reason is the same as above
  const signOutUrl = `${Urls.SIGNOUT}?source=${encoded}`;
  const protocol = window.location.protocol;
  const host = window.location.host;
  window.location.replace(`${protocol}//${host}${signOutUrl}`);
};

export const navigateToAdminPanel = () => {
  window.location.pathname = '/admin/';
};

export const navigateToCatalog = (openInNewWindow?: boolean) => {
  if (openInNewWindow) {
    window.open(Urls.CATALOG);
  } else {
    window.location.href = Urls.CATALOG;
  }
};

export const navigateToDashboard = (openInNewWindow?: boolean) => {
  if (openInNewWindow) {
    window.open(Urls.DASHBOARD);
  } else {
    window.location.href = Urls.DASHBOARD;
  }
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ServerErrorComponent,
    CookieConsentWidgetComponent,
    IntercomWidgetComponent,
    LogrocketWidgetComponent,
    AvailableHintsDialogComponent,
    QuestionCreateDialogComponent,
  ],
  imports: [
    BreadcrumbModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JsonApiModule,
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      urlUpdateStrategy: 'eager',
      scrollPositionRestoration: 'enabled',
    }),
    FlatworldMaterialModule,
    DigitalClockModule,
    LoadingIndicatorModule,
    MathJaxModule.forRoot(),
    PromptersModule.forRoot(),
    SnackbarModule,
    AuthenticationModule.forRoot({
      navigateToLoginPage,
      navigateToSignOutPage,
      mountPath: 'auth',
    }),
    NavigationModule.forRoot({
      navigateToAdminPanel,
      navigateToDashboard,
      navigateToCatalog,
    }),
    PipesModule,
    FormsModule,
  ],
  providers: [
    EnvService,
    Datastore,
    HttpTrafficWatch,
    NavigationWatch,
    {
      provide: LoadingWatch,
      useClass: AppLoadingWatch,
    },
    AirbrakeCredentialsService,
    ApplicationNavigationTypeService,
    ChapterService,
    ClipboardService,
    IntercomCredentialsService,
    LogRocketService,
    ModelInvalidationService,
    PaginationService,
    ScrollService,
    SessionStorageService,
    SurveyService,
    TitleService,
    EmbeddedResolve,
    AssignmentNamer,
    BookNamer,
    ChapterNamer,
    CourseNamer,
    LtiCourseNamer,
    QuestionNamer,
    {
      provide: APP_INITIALIZER,
      useFactory: (
        logRocketService: LogRocketService,
        intercomCredentialsService: IntercomCredentialsService,
        notifier: Notifier,
      ) => (
        (): Promise<void> => (
          new Promise<void>((resolve, _reject) => {
            // Initialize LogRocket
            logRocketService.init().subscribe();

            // Set intercomAppId for Intercom script (in index.html)
            window['intercomAppId'] = intercomCredentialsService.getConfig();

            // Insert Learnosity script elements
            insertLearnosityScripts()
              .catch(error => {
                console.error('Learnosity script insertion error:', error);
                notifier.onError('Due to a partner outage, certain Homework functionality is currently unavailable.',
                  error, null, { duration: 0 });
              })
              .finally(() => resolve());
          })
        )
      ),
      deps: [
        LogRocketService,
        IntercomCredentialsService,
        Notifier,
      ],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: ServerErrorInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useExisting: HttpTrafficWatch,
    }, {
      provide: ErrorHandler,
      useClass: AirbrakeErrorHandler,
    },
    {
      provide: 'fetchUser',
      useFactory: (datastore: Datastore, paginationService: PaginationService) => (
        (userId: number): Observable<User> => {
          const user$ = datastore.findRecord(User, userId.toString(), {
            include: 'roles',
          });
          const taughtCourses$ = paginationService.all(Course, {
            filter: {
              taughtBy: userId,
            },
            sort: '-startDate,-endDate,-createdAt',
            include: 'book,book.titleFamilyEditions,invitation,originalCopy',
          });
          const enrolledCourses$ = paginationService.all(Course, {
            filter: {
              enrolledBy: userId,
            },
            sort: '-startDate,-endDate,-createdAt',
            include: 'book,invitation',
          });
          return combineLatest([user$, taughtCourses$, enrolledCourses$])
            .pipe(
              tap(([user, taughtCourses, enrolledCourses]: [User, Course[], Course[]]) => {
                user.taughtCourses = taughtCourses;
                user.enrolledCourses = enrolledCourses;
              }),
              map(([user, _taughtCourses, _enrolledCourses$]: [User, Course[], Course[]]) => user),
            );
        }
      ),
      deps: [Datastore, PaginationService],
    },
    {
      provide: 'afterAuthorization',
      useFactory: (router: Router, navigationTypeService: ApplicationNavigationTypeService) => {
        const sendToReturnUrl = (options: IDestinationOptions) => {
          if (_.startsWith(options.path, '/admin')) {
            return window.location.replace(decodeURIComponent(options.path));
          }
          router.navigate([options.path], { queryParams: options.params, replaceUrl: true });
        };

        let isInLti: boolean;
        navigationTypeService.isInLtiAttempt.subscribe(isInLtiAttempt => isInLti = isInLtiAttempt);

        return (user: User, returnOptions: IDestinationOptions) => {
          if (user.hasRole('collaborator') &&
            !(isInLti || user.hasTaughtCourses || user.hasEnrolledCourses)) {
            sendToReturnUrl({ path: '/editorial', params: {} });
            return;
          }

          sendToReturnUrl(returnOptions);
        };
      },
      deps: [Router, ApplicationNavigationTypeService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
