import { Component } from '@angular/core';
import { map } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { LocalStorage } from '../constants';
import { AuthService } from '../authentication/auth.service';
import { User } from '../model';

@Component({
  selector: 'app-cookie-consent-widget',
  templateUrl: './cookie-consent-widget.component.html',
  styleUrls: ['./cookie-consent-widget.component.scss'],
})
export class CookieConsentWidgetComponent {
  showWidget: boolean;

  get hasConsented(): boolean {
    return !!this.localStorageService.get(LocalStorage.COOKIE_CONSENT);
  }

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {
    this.setShowWidget();
  }

  consent() {
    this.storeConsented();
    this.showWidget = false;
  }

  private setShowWidget() {
    if (this.hasConsented) { return; }

    this.authService.currentUserLoaded.pipe(
      map((user: User) => this.showWidget = user.isFromLti && !user.adminPanelPermitted),
    ).subscribe();
  }

  private storeConsented() {
    this.localStorageService.set(LocalStorage.COOKIE_CONSENT, 'true');
  }
}
