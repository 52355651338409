import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type {
  AssignmentScoringType,
  AssignmentShowCorrectAnswers,
  AssignmentShowDetailedReport,
  AssignmentShowGrades,
  AssignmentShowSolutions,
  AssignmentType,
  Course,
} from './internal';
import { Model } from './model';

export type TemplateConfirmationType = 'update' | 'skip';

@JsonApiModelConfig({
  type: 'assignment-templates',
})
export class AssignmentTemplate extends Model {
  @Attribute() apply: boolean;

  @Attribute() startTime: Date;
  @Attribute() dueTime: Date;
  @Attribute() endTime: Date;

  @Attribute() kind: AssignmentType;
  @Attribute() timeLimit: number;
  @Attribute() attemptsLimit: number;
  @Attribute() showHints: boolean;
  @Attribute() showHintsForMcq: boolean;
  @Attribute() hintsLimit: number;
  @Attribute() applyHintsLimit: boolean;
  @Attribute() showChapterLink: boolean;
  @Attribute() showVideoLink: boolean;
  @Attribute() showGraphingCalculatorLink: boolean;
  @Attribute() lateSubmissionPenaltyPercent: number;
  @Attribute() repeatSubmissionPenaltyPercent: number;
  @Attribute() randomizeQuestionsPerStudent: boolean;
  @Attribute() randomizeQuestionsPerAttempt: boolean;
  @Attribute() scoringType: AssignmentScoringType;

  @Attribute() showGrades: AssignmentShowGrades;
  @Attribute() showGradesAfter: Date;
  @Attribute() showDetailedReport: AssignmentShowDetailedReport;
  @Attribute() showDetailedReportAfter: Date;
  @Attribute() showCorrectAnswers: AssignmentShowCorrectAnswers;
  @Attribute() showCorrectAnswersAfter: Date;
  @Attribute() showSolutions: AssignmentShowSolutions;
  @Attribute() showSolutionsAfter: Date;
  @Attribute() showSolutionsDuringAttempt: boolean;
  @Attribute() showSolutionVideoDuringAttempt: boolean;

  @Attribute() skipConfirmation: boolean;
  @Attribute() confirmationType: TemplateConfirmationType;

  @BelongsTo() course: Course;
}
