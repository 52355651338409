import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
  transform(quantity: number | string, singularText: string, omitQuantity?: boolean): string {
    const quantityText = !omitQuantity ? `${quantity} ` : '';

    const endingText = (+quantity !== 1) ? 's' : '';

    return `${quantityText}${singularText}${endingText}`;
  }
}
