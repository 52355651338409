import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  styleUrls: [
    './confirmation-dialog.component.scss',
  ],
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  @Input() title: string;
  @Input() question: string;
  @Input() cancel?: string;
  @Input() confirmation?: string;
}
