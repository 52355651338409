import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ISnackbarData } from '../../prompters/notifications';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  public data: ISnackbarData;

  constructor(
    private snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) data: any) {
    this.data = data;
  }

  public action() {
    this.snackBarRef.dismissWithAction();
  }

  public get notificationIsWarning(): boolean {
    return this.data.type === 'warning';
  }

  public get hasAction(): boolean {
    return !!this.data.actionName;
  }
}
