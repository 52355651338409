import { Attribute, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { User } from './internal';
import { Model } from './model';

export interface IBookStudentEngagement {
  user: User;
  lastAccess: string;
  lastAccessDaysAgo: string;
  accessCount: number;
  annotationCount: number;
  flashcardCount: number;
  quizQuestionAttemptsCount: number;
  searchCount: number;
  sectionCount: number;
  videosViewedCount: number;
  score: number;
}

@JsonApiModelConfig({
  type: 'course-engagements',
})
export class CourseEngagement extends Model {
  @Attribute() packet: IBookStudentEngagement[];
}
