import { Component, Injectable, NgZone, OnInit } from '@angular/core';
import { AuthService } from 'app/authentication/auth.service';
import { Environment, EnvService } from 'app/env.service';
import { User } from 'app/model/user';
import { environment } from 'environments/environment';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class IntercomCredentialsService {
  constructor(private envService: EnvService) { }

  getConfig(): string {
    return this.envService.isProduction
      ? environment.intercomAppId
      : environment.intercomAppIdStaging;
  }
}

@Component({
  selector: 'app-intercom-widget',
  templateUrl: './intercom-widget.component.html',
  styleUrls: ['./intercom-widget.component.scss'],
})
export class IntercomWidgetComponent implements OnInit {
  showClock: boolean;

  constructor(private authService: AuthService,
              private envService: EnvService,
              private intercomCredentialsService: IntercomCredentialsService,
              private ngZone: NgZone) { }

  private get inProdOrStagingEnv(): boolean {
    return this.envService.env !== Environment.Local;
  }

  ngOnInit() {
    this.authService.currentUser.pipe(
      filter(user => !!user),
      filter(() => this.shouldRun()),
      tap(user => this.initApi(user)),
    ).subscribe();
  }

  onHideClock() {
    this.showClock = false;
  }

  private onShowIntercom() {
    // Intercom.onShow (outside of Angular) triggers this method,
    // so we use ngZone.run for proper change detection
    this.ngZone.run(() => this.showClock = true);
  }

  private initApi(user: User) {
    // Intercom global obj is loaded in index.html
    if (!Intercom) { return; }

    Intercom('boot', {
      app_id: this.intercomCredentialsService.getConfig(),
      email: user.email,
      user_id: user.flatworldId,
      user_hash: user.intercomHash,
      fwkApplication: 'Homework',
    });

    Intercom('onShow', () => this.onShowIntercom());
  }

  private shouldRun(): boolean {
    return this.inProdOrStagingEnv;
  }
}
