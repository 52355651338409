import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Book } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'accesses',
})
export class Access extends Model {
  @Attribute() packet: boolean;
  @BelongsTo() book: Book;
}
