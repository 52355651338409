import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/authentication/auth.service';
import { Urls } from 'app/constants';
import { Book, User } from 'app/model';
import { RoleType } from 'app/model/question-error-report';
import { SessionStorageService } from 'app/session-storage.service';
import _ from 'lodash';
import { filter, tap } from 'rxjs/operators';

interface ISurveyConfig {
  url: string;
  active: boolean;
}

interface ISurveyInitialData {
  action: 'populateConcierge';
  uuid: string;
  role: RoleType;
  source: 'practice';
  book_id: number;
}

interface IWrapperElement extends HTMLElement {
  attachWidget: ({ src }: { src: string }) => void;
}

@Injectable()
export class SurveyService {
  private static STORAGE_KEY = 'practice_survey';
  private static SCRIPT_ELEMENT_ID = 'surveyScript';
  private static WRAPPER_ELEMENT_ID = 'surveyWrapper';
  private static WIDGET_JS = 'widget.js';

  baseUrl: string;
  book: Book;
  role: RoleType;
  user: User;

  constructor(
    private authService: AuthService,
    private sessionStorage: SessionStorageService,
    private http: HttpClient) { }

  private get surveyedBooks(): string[] {
    return JSON.parse(this.sessionStorage.get(SurveyService.STORAGE_KEY)) || [];
  }

  private get wrapperElement(): IWrapperElement {
    return <IWrapperElement>document.getElementById(SurveyService.WRAPPER_ELEMENT_ID);
  }

  private get scriptElement(): HTMLScriptElement {
    if (!this.wrapperElement) { return; }

    return this.wrapperElement.querySelector(`#${SurveyService.SCRIPT_ELEMENT_ID}`);
  }

  private get iframeElement(): HTMLIFrameElement {
    if (!this.wrapperElement) { return; }

    return this.wrapperElement.querySelector('iframe');
  }

  init(book: Book, isEducator: boolean) {
    this.authService.currentUser.pipe(
      filter(user => !!user),
      tap(user => {
        this.user = user;
        this.book = book;
        this.role = isEducator ? 'educator' : 'student';
      }),
    ).subscribe(() => this.initializeSurvey());
  }

  private initializeSurvey() {
    // Ensure book exists and user not already surveyed for book
    if (!this.book || this.alreadySurveyed(this.book)) { return; }

    // Ensure survey not already loaded into DOM
    if (this.scriptElement || this.wrapperElement) { return; }

    // Get survey config and continue only if active
    this.http.get(Urls.SURVEY_CONFIG).pipe(
      filter((config: ISurveyConfig) => config.active),
      tap((config: ISurveyConfig) => {
        this.baseUrl = config.url;
        this.createSurveyElements();
        this.markAsSurveyed(this.book);
      }),
    ).subscribe();
  }

  private createSurveyWrapper() {
    const surveyEl = document.createElement('div');
    surveyEl.id = SurveyService.WRAPPER_ELEMENT_ID;
    document.body.appendChild(surveyEl);
  }

  private createSurveyScript() {
    if (!this.wrapperElement) { return; }

    const scriptEl = document.createElement('script');
    scriptEl.id = SurveyService.SCRIPT_ELEMENT_ID;
    scriptEl.src = `${this.baseUrl}/${SurveyService.WIDGET_JS}`;
    scriptEl.onload = () => {
      this.wrapperElement.attachWidget({ src: this.baseUrl });
    };

    window.addEventListener('message', this.handleMessage);

    this.wrapperElement.appendChild(scriptEl);
  }

  private createSurveyElements() {
    this.createSurveyWrapper();
    this.createSurveyScript();
  }

  private handleMessage = (event: MessageEvent) => {
    if (event.origin !== this.baseUrl) { return; }

    switch (event.data) {
      case 'populateConcierge':
        return this.populateSurvey();
      case 'displayConcierge':
        return this.displaySurvey();
      case 'destroyConcierge':
        return this.destroySurvey();
    }
  };

  private populateSurvey() {
    if (!this.iframeElement) { return; }

    const initialData: ISurveyInitialData = {
      action: 'populateConcierge',
      uuid: this.user.uuid,
      role: this.role,
      source: 'practice',
      book_id: +this.book.flatworldId,
    };

    this.iframeElement.contentWindow.postMessage(initialData, this.baseUrl);
  }

  private displaySurvey() {
    if (!this.iframeElement) { return; }

    // Display w/fade-in animation
    this.iframeElement.style.right = '90px';
    this.iframeElement.style.maxWidth = 'min(100vw - 100px, 500px)';
    this.iframeElement.style.display = 'block';
    setTimeout(() => {
      if (!this.iframeElement) { return; }
      this.iframeElement.style.opacity = '1';
    }, 800);
  }

  private destroySurvey() {
    window.removeEventListener('message', this.handleMessage);

    // Destroy w/fade-out animation
    if (this.iframeElement) {
      this.iframeElement.style.transition = 'opacity .2s';
      this.iframeElement.style.opacity = '0';
    }

    setTimeout(() => {
      if (!this.wrapperElement) { return; }
      this.wrapperElement.parentElement.removeChild(this.wrapperElement);
    }, 200);
  }

  private alreadySurveyed(book: Book): boolean {
    return _.includes(this.surveyedBooks, book.id);
  }

  private markAsSurveyed(book: Book) {
    const updated = _.uniq([...this.surveyedBooks, book.id]);
    this.sessionStorage.set(SurveyService.STORAGE_KEY, JSON.stringify(updated));
  }
}
