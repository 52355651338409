import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Course } from 'app/model';
import _ from 'lodash';

@Component({
  selector: 'app-blueprint-icon',
  templateUrl: './blueprint-icon.component.html',
  styleUrls: ['./blueprint-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlueprintIconComponent {
  @Input() course: Course;

  @HostBinding('class.hidden')
  get hidden(): boolean {
    return !this.course?.blueprint;
  }
}
