import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { LearnosityQuestion } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'recent-usages',
})
export class RecentUsage extends Model {
  @Attribute() packet: boolean;
  @BelongsTo() learnosityQuesiton: LearnosityQuestion;
}
