import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(text: string, desiredLength: number): any {
    return _.truncate(text, {
      length: desiredLength || 10,
    });
  }
}
