import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../custom-components/confirmation-dialog/confirmation-dialog.component';
import { UnsavedChangesDialogComponent } from '../custom-components/unsaved-changes-dialog/unsaved-changes-dialog.component';

export abstract class Prompter {
  abstract confirmation(options: IConfirmationOptions): Observable<boolean>;
  abstract unsavedChanges(options?: IUnsavedChangesOptions): Observable<UnsavedChangesDialogResponse>;
}

export interface IConfirmationOptions {
  title: string;
  question: string;
  cancel?: string;
  confirmation?: string;
  disableClose?: boolean;
  panelClass?: string | string[];
}

export interface IUnsavedChangesOptions {
  title?: string;
  question?: string;
  discard?: string;
  save?: string;
  disableClose?: boolean;
  panelClass?: string | string[];
}

export enum UnsavedChangesDialogResponse {
  Discard = 'discard',
  Save = 'save',
  Cancel = 'cancel'
}

@Injectable()
export class DialogPrompter extends Prompter {
  constructor(private dialog: MatDialog) {
    super();
  }

  confirmation(options: IConfirmationOptions): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: options.disableClose,
      panelClass: options.panelClass,
    });

    const component = dialogRef.componentInstance;

    component.title = options.title;
    component.question = options.question;
    component.cancel = options.cancel;
    component.confirmation = options.confirmation;

    return dialogRef.afterClosed();
  }

  unsavedChanges(options: IUnsavedChangesOptions = {}): Observable<UnsavedChangesDialogResponse> {
    const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
      disableClose: options.disableClose,
      panelClass: options.panelClass,
    });

    const component = dialogRef.componentInstance;

    if (options) {
      component.title = options.title;
      component.question = options.question;
      component.discard = options.discard;
      component.save = options.save;
      component.disableClose = options.disableClose;
    }

    return dialogRef.afterClosed();
  }
}
