import { Attribute, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import { Model } from './model';

export interface IExternalResourcesPacket {
  url: string;
  video: string;
  graphing: boolean;
  solution_video: string;
}

@JsonApiModelConfig({
  type: 'external-resources',
})
export class ExternalResource extends Model {
  @Attribute() packet: IExternalResourcesPacket;
}
