import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appMax]',
  providers: [{provide: NG_VALIDATORS, useExisting: MaxDirective, multi: true}],
})
export class MaxDirective implements Validator {
  @Input() appMax: number;

  validate(c: FormControl<number>): {[key: string]: any} {
    const v = c.value;
    return ( v > this.appMax) ? { appMax: true } : null;
  }
}
