import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccessTokenStorageService } from 'app/authentication/access-token-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from '../local-storage.service';
import { AuthenticationFacade } from './auth.facade';
import { AuthGuard } from './auth.guard';
import { AuthenticationStorage, AuthInterceptor, AuthService } from './auth.service';
import { CourseUserRoleProvider } from './course-user-role';
import { LocationService } from './location.service';
import { LoginPageNavigator, SignoutPageNavigator } from './return-options';
import { UserIdStorageService } from './user-id-storage.service';

export interface IAuthenticationModuleOptions {
  navigateToLoginPage: LoginPageNavigator;
  navigateToSignOutPage: SignoutPageNavigator;
  mountPath: string;
}

@NgModule({
  imports: [
    CommonModule,
  ],
})
export class AuthenticationModule {
  public static forRoot(options: IAuthenticationModuleOptions): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        {
          provide: 'navigateToLoginPage',
          useValue: options.navigateToLoginPage,
        },
        {
          provide: 'navigateToSignOutPage',
          useValue: options.navigateToSignOutPage,
        },
        {
          provide: 'mountPath',
          useValue: options.mountPath,
        },
        CookieService,
        LocationService,
        AccessTokenStorageService,
        UserIdStorageService,
        LocalStorageService,
        AuthenticationStorage,
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: AuthInterceptor,
        },
        AuthService,
        CourseUserRoleProvider,
        AuthenticationFacade,
        AuthGuard,
      ],
    };
  }
}
