import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlatworldMaterialModule } from '../flatworld-material/flatworld.material.module';
import { BlueprintIconComponent } from './blueprint-icon/blueprint-icon.component';

@NgModule({
  declarations: [
    BlueprintIconComponent,
  ],
  imports: [
    FlatworldMaterialModule,
    CommonModule,
  ],
  exports: [
    BlueprintIconComponent,
  ],
})
export class BlueprintIconModule { }
