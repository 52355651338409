import { Component } from '@angular/core';
import { from, Observable, of, timer } from 'rxjs';
import { delay, distinctUntilChanged, map, scan, shareReplay, switchMap, zipWith } from 'rxjs/operators';
import { HttpTrafficWatch } from '../../loading/http-traffic-watch';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent {
  public value$: Observable<number>;
  public started$: Observable<boolean>;

  constructor(loadingWatch: HttpTrafficWatch) {
    this.value$ = loadingWatch.count$.pipe(
      map(count => count > 0),
      switchMap(loading => {
        const timeout = loading ? 0 : 250;
        return of(loading).pipe(delay(timeout));
      }),
      distinctUntilChanged(),
      switchMap((loading) => {
        if (!loading) {
          return from([1, 0]).pipe(
            zipWith(timer(100, 250)),
            map(([a, _b]) => a));
        }

        return timer(0, 250).pipe(scan((acc: number, _timer) => acc + this.updateHeuristic(acc), 0.02));
      }),
      map(n => n * 100),
      shareReplay() );

    this.started$ = this.value$.pipe(
      map(value => value > 0),
      shareReplay() );
  }

  private updateHeuristic(stat: number): number {
    if (stat >= 0 && stat < 0.25) {
      // Start out between 3 - 6% increments
      return (Math.random() * (5 - 3 + 1) + 3) / 100;
    } else if (stat >= 0.25 && stat < 0.65) {
      // increment between 0 - 3%
      return (Math.random() * 3) / 100;
    } else if (stat >= 0.65 && stat < 0.9) {
      // increment between 0 - 2%
      return (Math.random() * 2) / 100;
    } else if (stat >= 0.9 && stat < 0.99) {
      // finally, increment it .5 %
      return 0.005;
    } else {
      // after 99%, don't increment:
      return 0;
    }
  }
}
