import { BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Course, User } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'teachings',
})
export class Teaching extends Model {
  @BelongsTo() educator: User;
  @BelongsTo() course: Course;
}
