import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';

@Directive()
export abstract class BaseAuthDirective implements OnInit, OnDestroy {
  private subscription: Subscription;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService) {
  }

  ngOnDestroy() {
    if (!this.subscription) {
      return;
    }
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.canBeViewed(this.authService)
      .subscribe((canBeViewed) => {
        if (canBeViewed) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }
  protected abstract canBeViewed(authService: AuthService): Observable<boolean>;
}
