import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Question } from '../internal';
import { Model } from '../model';

@JsonApiModelConfig({
  type: 'learnosity/question-previews',
})
export class QuestionPreview extends Model {
  @BelongsTo() question: Question;
  @Attribute() packet: ILearnosityItemsApiInitializationObject;
}
