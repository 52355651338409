import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlatworldMaterialModule } from '../flatworld-material/flatworld.material.module';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';

@NgModule({
  declarations: [
    LoadingBarComponent,
  ],
  imports: [
    FlatworldMaterialModule,
    CommonModule,
  ],
  exports: [
    LoadingBarComponent,
  ],
})
export class LoadingIndicatorModule { }
