import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlatworldMaterialModule } from '../flatworld-material/flatworld.material.module';
import { BlueprintIconModule } from './blueprint-icon.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './breadcrumb/breadcrumb.service';

export { INamer, Name } from './breadcrumb/breadcrumb.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlatworldMaterialModule,
    BlueprintIconModule,
  ],
  declarations: [
    BreadcrumbComponent,
  ],
  exports: [
    BreadcrumbComponent,
  ],
})
export class BreadcrumbModule {
  public static forRoot(): ModuleWithProviders<BreadcrumbModule> {
    return {
      ngModule: BreadcrumbModule,
      providers: [
        BreadcrumbService,
      ],
    };
  }
}
