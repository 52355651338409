import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-digital-clock',
  templateUrl: './digital-clock.component.html',
  styleUrls: ['./digital-clock.component.scss'],
})
export class DigitalClockComponent implements OnInit, OnDestroy {
  @Input() show: boolean;
  @Output() hide: EventEmitter<void> = new EventEmitter();
  currentTime = new Date();
  clockSubscription: Subscription;
  private unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.startClock();
  }

  ngOnDestroy() {
    this.stopClock();
  }

  hideClock() {
    this.hide.emit();
  }

  private startClock() {
    this.clockSubscription = interval(1000).pipe(
      tap(() => this.setCurrentTime()),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  private stopClock() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.currentTime = null;
  }

  private setCurrentTime() {
    this.currentTime = new Date();
  }
}
