import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Attempt, IReport } from '../internal';
import { Model } from '../model';

@JsonApiModelConfig({
  type: 'learnosity/student-details-assignment-reports',
})
export class StudentDetailsAssignmentReport extends Model implements IReport {
  @BelongsTo() attempt: Attempt;
  @Attribute() packet: ILearnosityReportsApiInitializationObject;
}
