import { Directive, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appOnAttemptRoute]',
  exportAs: 'appOnAttemptRoute',
})
export class OnAttemptRouteDirective implements OnDestroy {
  public isActive = true;
  private unsubscribe$ = new Subject<void>();

  constructor(private router: Router) {
    router.events.pipe(
       takeUntil(this.unsubscribe$),
       filter((e: RouterEvent) => e instanceof NavigationEnd),
       startWith(<Event>null),
       tap(() => this.updateState()),
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateState() {
    const url = this.router.url;
    const onAttempt = url.includes('attempt');
    const onPreview = url.includes('preview');
    const onStart = url.includes('start');

    this.isActive = onAttempt || onPreview || onStart;
  }
}
