import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { Injectable, Injector, Type } from '@angular/core';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import _ from 'lodash';

export interface IBreadcrumb {
  label: string;
  url: string;
}

export type IRootBreadcrumb = string;

export type Name = string;

export interface INamer {
  resolve: (snapshot: ActivatedRouteSnapshot) => Observable<Name>;
}

const isName = (namer: INamer | Name): namer is Name => (
  typeof namer === 'string'
);

@Injectable()
export class BreadcrumbService {
  static readonly ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';
  static readonly ROUTE_DATA_BREADCRUMB_PREFIX: string = 'breadcrumbPrefix';

  allBreadcrumbs$: Observable<IBreadcrumb[]>;
  rootBreadcrumb$: Observable<IRootBreadcrumb>;
  hideBreadcrumbs$: Observable<boolean>;
  breadcrumbs$ = new ReplaySubject<IBreadcrumb[]>(1);

  private subscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private injector: Injector,
  ) {
    this.initialize();
  }

  refresh() {
    if (this.subscription) { this.subscription.unsubscribe(); }
    this.initialize();
  }

  private initialize() {
    const rootRoutes$: Observable<ActivatedRoute> = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(<Event>null),
        map(_event => this.activatedRoute.root),
        shareReplay(1),
      );

    this.allBreadcrumbs$ = rootRoutes$
      .pipe(
        switchMap(root => {
          const breadcrumbs = this.getBreadcrumbs(root);

          return combineLatest(breadcrumbs);
        }),
        map(this.scanForDifferent),
        shareReplay(1),
      );

    this.rootBreadcrumb$ = rootRoutes$
      .pipe(
        map(root => this.getRootBreadcrumb(root)),
        shareReplay(1),
      );

    this.hideBreadcrumbs$ = rootRoutes$
      .pipe(
        map(root => this.getHideProperty(root)),
        shareReplay(1),
      );

    this.subscription = this.allBreadcrumbs$.subscribe(breadcrumbs => this.breadcrumbs$.next(breadcrumbs));
  }

  private getBreadcrumbs(root: ActivatedRoute, url: string = ''): Observable<IBreadcrumb>[] {
    const breadcrumbs: Observable<IBreadcrumb>[] = [];
    let current = root;

    while (current = this.findPrimaryChild(current)) {
      url += this.routePath(current);

      if (current.snapshot.data.hasOwnProperty(BreadcrumbService.ROUTE_DATA_BREADCRUMB)) {
        const breadcrumb = this.getBreadcrumb(current.snapshot, url);
        breadcrumbs.push(breadcrumb);
      }
    }
    return breadcrumbs;
  }

  private scanForDifferent(breadcrumbs: IBreadcrumb[]): IBreadcrumb[] {
    return _.reduce(breadcrumbs, (res: IBreadcrumb[], bc: IBreadcrumb): IBreadcrumb[] => {
      const lastUrl = res[res.length - 1].url;
      if (bc.url !== lastUrl) {
        res.push(bc);
      }
      return res;
    }, [breadcrumbs[0]]);
  }

  private findPrimaryChild(route: ActivatedRoute): ActivatedRoute {
    const children = route.children;
    for (const child of children) {
      if (child.outlet === PRIMARY_OUTLET) {
        return child;
      }
    }
    return null;
  }

  private routePath(route: ActivatedRoute): string {
    if (route.snapshot.url.length === 0) {
      return '';
    }
    return [''].concat(route.snapshot.url.map(segment => segment.path))
      .join('/');
  }

  private getBreadcrumb(snapshot: ActivatedRouteSnapshot, url: string): Observable<IBreadcrumb> {
    const declared = snapshot.data[BreadcrumbService.ROUTE_DATA_BREADCRUMB];
    const prefix = snapshot.data[BreadcrumbService.ROUTE_DATA_BREADCRUMB_PREFIX];

    let label$: Observable<Name>;
    if (isName(declared)) {
      label$ = of(declared);
    } else {
      const namer = this.injector.get(<Type<INamer>>declared);
      label$ = namer.resolve(snapshot);
    }

    return label$.pipe(
      map((label: Name) => {
        if (prefix) {
          label = `${prefix} | ${label}`;
        }
        return {
          label,
          url,
        };
      }));
  }

  private getRootBreadcrumb(root: ActivatedRoute): any {
    let routeSnapshot = root.snapshot;

    while (routeSnapshot.firstChild) {
      if (routeSnapshot.data.rootBreadcrumb) {
        return routeSnapshot.data.rootBreadcrumb;
      }

      routeSnapshot = routeSnapshot.firstChild;
    }

    return 'Homework';
  }

  private getHideProperty(root: ActivatedRoute): boolean {
    let routeSnapshot = root.snapshot;

    while (routeSnapshot.firstChild) {
      if (routeSnapshot.data.hideAllBreadcrumbs || routeSnapshot.firstChild.data.hideAllBreadcrumbs) { return true; }
      if (routeSnapshot.data.hideBreadcrumb) { return true; }
      routeSnapshot = routeSnapshot.firstChild;
    }

    return false;
  }
}
