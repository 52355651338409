import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { Model } from '../model/model';

@Pipe({
  name: 'removeSelected',
  pure: false,
})
export class AutocompleteRemoveSelectedPipe implements PipeTransform {
  transform<T extends Model>(items: T[], alreadySelected: T[]) {
    return <T[]>_.reject(items, (item) => _.find(alreadySelected, { id: item.id }));
  }
}
