<div class="question-create-dialog">
  <h2 mat-dialog-title>Create Question</h2>
  <mat-dialog-content>
    <mat-radio-group name="selectedGradingType"
                    [(ngModel)]="selectedGradingType">
      <div class="option">
        <mat-radio-button [value]="'auto'" checked>
          <div class="option-title">
            Auto-graded question
          </div>
          <div class="option-description">
            Scores for students calculated instantly and automatically. More than twenty question types are auto-graded, including multiple choice, fill in the blanks, chemistry formulas, and math questions.
          </div>
        </mat-radio-button>
      </div>
      <div class="option" *ngIf="supportsManualGrading">
        <mat-radio-button [value]="'manual'">
          <div class="option-title">
            Manually graded question
          </div>
          <div class="option-description">
            Scores for students are manually entered by the instructor. Essays and video or audio recordings (along with a few other question types) are manually graded.
          </div>
        </mat-radio-button>
      </div>
      <div class="option" *ngIf="supportsExcelGrading">
        <mat-radio-button [value]="'excel'">
          <div class="option-title">
            Excel Grader
          </div>
          <div class="option-description">
            Scores for students are automatically calculated after they submit their Excel files.
          </div>
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button
            mat-dialog-close
            color="primary">
      Cancel
    </button>
    <button mat-raised-button
            color="primary"
            (click)="submit()">
      Create
    </button>
  </mat-dialog-actions>
</div>
