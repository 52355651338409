import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class UserIdStorageService {
  private static KEY = 'user_id';

  private userIdSubject: BehaviorSubject<number>;
  private observable: Observable<number>;

  constructor(private localStorage: LocalStorageService) {
    this.userIdSubject = new BehaviorSubject<number>(this.readUserId());
    this.userIdSubject.subscribe(this.writeUserId.bind(this));
    this.observable = this.userIdSubject
      .asObservable().pipe(
      distinctUntilChanged());
  }

  public get userId$(): Observable<number> {
    return this.observable;
  }

  public get userId(): number {
    return this.userIdSubject.value;
  }

  public set userId(value: number) {
    this.userIdSubject.next(value);
  }

  private readUserId(): number {
    return parseInt(this.localStorage.get(UserIdStorageService.KEY), 10)
      || null;
  }

  private writeUserId(value: number) {
    this.localStorage.set(UserIdStorageService.KEY, value);
  }
}
