import { Component, OnInit } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service';
import { LogRocketService } from './logrocket.service';

@Component({ selector: 'app-logrocket-widget', template: '' })
export class LogrocketWidgetComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private logRocketService: LogRocketService,
  ) { }

  ngOnInit() {
    this.authService.currentUser.pipe(
      filter(user => !!user),
      switchMap((user) => this.logRocketService.identify(user)),
    ).subscribe();
  }
}
