import { Attribute, HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Book, Question, Taggable } from '../internal';
import { Model } from '../model';

export type ScopeType = 'book' | 'chapter' | 'author';

@JsonApiModelConfig({
  type: 'learnosity::Tags',
})
export class Tag extends Model {
  @Attribute() scope: ScopeType;
  @Attribute() value: string;

  @HasMany({
    key: 'learnosity::Taggable',
  })
  taggables: Taggable[];
  @HasMany() books: Book[];
  @HasMany() questions: Question[];
}
