import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { LearnosityQuestion, Question } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'variants',
})
export class Variant extends Model {
  @Attribute() exam: boolean;
  @BelongsTo() question: Question;

  @BelongsTo({
    key: 'learnosity::Question',
  })
  'learnosity::Question': LearnosityQuestion;

  public get learnosityQuestion(): LearnosityQuestion {
    return this['learnosity::Question'];
  }
}
