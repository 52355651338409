import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'app/pipes/pipes.module';
import { FlatworldMaterialModule } from '../flatworld-material/flatworld.material.module';
import { SnackbarComponent } from './snackbar/snackbar.component';

@NgModule({
  declarations: [
    SnackbarComponent,
  ],
  imports: [
    FlatworldMaterialModule,
    CommonModule,
    PipesModule,
  ],
  exports: [
    SnackbarComponent,
  ],
})
export class SnackbarModule { }
