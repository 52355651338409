import { Pipe, PipeTransform } from '@angular/core';
import { Assignment } from '../model/index';

@Pipe({
  name: 'assignmentStatus',
  pure: false,
})
export class AssignmentStatusPipe implements PipeTransform {

  transform(assignment: Assignment, _args?: any): any {
    return assignment.published ? 'published' : 'draft';
  }
}
