import { Attribute, BelongsTo, HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import { IConfirmationOptions } from 'app/prompters/prompts';
import _ from 'lodash';
import moment from 'moment';
import type {
  Assignment,
  Book,
  CourseBookUpgrade,
  CourseCopy,
  CourseGrade,
  Enrollment,
  Exemption,
  Institution,
  Invitation,
  LtiCourse,
  Teaching,
  User,
} from './internal';
import {
  Model,
  Validation,
} from './model';

const MIN_DAYS_FROM_CREATED_AT_TO_ARCHIVE = 120;

// Simple email regex asserting:
// 1+ word character (letter/number/underscore) before "@"
// 1+ word character after "@" and before "."
// 2+ word characters after "."
// e.g. valid: a@b.co, 123@fwk.com
export const EMAIL_REGEX = /\w+@\w+\.\w{2,}/;

export type ErrorReportHandlingType = 'unspecified' | 'educators' | 'flatworld';

@JsonApiModelConfig({
  type: 'courses',
})
export class Course extends Model {
  @Attribute() name: string;
  @Attribute() demo: boolean;
  @Attribute() replacement: boolean;
  @Attribute() blueprint: boolean;
  @Attribute() isFromBlueprint: boolean;
  @Attribute() canvasId: string;
  @Attribute() consumerKey: string;
  @Attribute() createdAt: Date;
  @Attribute() startDate: Date;
  @Attribute() endDate: Date;
  @Attribute() description: string;
  @Attribute() enrollmentsCount: number;
  @Attribute() courseIdentifier: string;
  @Attribute() sectionIdentifier: string;
  @Attribute() isFromLti: boolean;
  @Attribute() lmsType: string;
  @Attribute() supportsAssignmentDates: boolean;
  @Attribute() bookPurchaseUrl: string;
  @Attribute() studentsEmailList: string;
  @Attribute() educatorsEmailList: string;
  @Attribute() selfEnrollmentAllowed: boolean;
  @Attribute() enrollmentsEditor: string;
  @Attribute() hasCopies: boolean;
  @Attribute() hasExams: boolean;
  @Attribute() hasQuickCreate: boolean;
  @Attribute() hasExcelGrading: boolean;
  @Attribute() hasManualGrading: boolean;
  @Attribute() institution: string;
  @Attribute() originalCourseId: string;
  @Attribute() errorReportHandling: ErrorReportHandlingType;
  @Attribute() unpublishableAssignmentsCount: number;
  @Attribute() unpublishableAssignmentsWithTextbookQuestionsOnlyCount: number;
  @Attribute() upgradeDeclined: boolean;
  @Attribute() isUpgradeable: boolean;
  @Attribute() forthcomingCopyName: string;

  @BelongsTo() book: Book;
  @BelongsTo() canonicalInstitution: Institution;
  @BelongsTo() courseBookUpgrade: CourseBookUpgrade;
  @BelongsTo() courseGrade: CourseGrade;
  @BelongsTo() ltiCourse: LtiCourse;
  @BelongsTo() invitation: Invitation;
  @BelongsTo() accountOwner: User;
  @BelongsTo() originalCopy: CourseCopy;

  @HasMany() educators: User[];
  @HasMany() students: User[];
  @HasMany() teachings: Teaching[];
  @HasMany() enrollments: Enrollment[];
  @HasMany() assignments: Assignment[];
  @HasMany() exemptions: Exemption[];

  @Validation(['End date'], 'must be after the start date.') validEndDate() {
    if (!this.hasStartDate || !this.hasEndDate) {
      return true;
    }

    return this.startDate < this.endDate;
  }

  @Validation('Students list', 'must contain at least one email address.') validStudentsEmailList() {
    if (_.isNil(this.studentsEmailList)) { return true; }

    return EMAIL_REGEX.test(this.studentsEmailList);
  }

  get bookAccessible(): boolean {
    return this.book.accessible;
  }

  get canBeArchived(): boolean {
    return !this.isFromLti && (this.finished ||
      moment(this.createdAt).add(MIN_DAYS_FROM_CREATED_AT_TO_ARCHIVE, 'd').isSameOrBefore(moment(), 'd')
    );
  }

  get canBeCopied(): boolean {
    return !this.isFromLti && !this.isFromBlueprint;
  }

  get canBeUpgraded(): boolean {
    return this.isUpgradeable && this.book?.canBeUpgraded;
  }

  get canBeReplicated(): boolean {
    return !this.isFromLti && this.blueprint;
  }

  get canToggleBlueprint(): boolean {
    // TODO: Restore logic when releasing Blueprint course functionality
    // return !this.hasCopies && !this.isFromBlueprint;
    return false;
  }

  get hasUnpublishableAssignments(): boolean {
    return this.unpublishableAssignmentsCount > 0;
  }

  get finished(): boolean {
    return moment(this.endDate).isBefore(moment(), 'd');
  }

  get shouldShowUpgradePrompt(): boolean {
    return this.isFromLti && (this.hasUnpublishableAssignments || (this.canBeUpgraded && !this.upgradeDeclined));
  }

  get upgradePromptOptions(): IConfirmationOptions {
    const title = this.canBeUpgraded ? 'Update Course' : 'Review Questions';

    const question = this.canBeUpgraded
      ? `We have published an updated version of <cite>${this.book.name}</cite>.\
    <br><br>Would you like to update your course to use the new version?`
      : `Some of the questions used in your course need to be reviewed.\
    <br><br>Would you like to review the questions now?`;

    return {
      title,
      question,
      confirmation: this.canBeUpgraded ? 'Update' : 'Review',
      cancel: this.canBeUpgraded ? 'Do Not Update' : 'Cancel',
      disableClose: true,
    };
  }

  private get hasStartDate(): boolean {
    return !!(this.startDate && !isNaN(this.startDate.valueOf()));
  }

  private get hasEndDate(): boolean {
    return !!(this.endDate && !isNaN(this.endDate.valueOf()));
  }

  copyInProgress() {
    return this.originalCopy && !this.originalCopy.finished;
  }

  hasAssignment(id: string): boolean {
    return _.some(this.assignments, ['id', id]);
  }

  isLmsType(lmsType: string): boolean {
    return this.isFromLti && this.lmsType && (this.lmsType.toLowerCase() === lmsType.toLowerCase());
  }
}
