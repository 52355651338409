import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss'],
})
export class UnsavedChangesDialogComponent {
  @Input() title?: string;
  @Input() question?: string;
  @Input() discard?: string;
  @Input() save?: string;
  @Input() disableClose?: boolean;
}
