import {
  ComponentRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';

@Directive({
  selector: '[appLoading]',
})
export class LoadingDirective {
  private componentRef: ComponentRef<SpinnerComponent>;

  @Input() public set appLoading(loading: boolean) {
    this.vcRef.clear();

    if (loading) {
      this.componentRef = this.vcRef.createComponent(SpinnerComponent);
      return;
    }

    this.vcRef.createEmbeddedView(this.templateRef);
    if (this.componentRef) { this.componentRef.destroy(); }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef,
  ) { }
}
