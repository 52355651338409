import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlatworldMaterialModule } from 'app/flatworld-material/flatworld.material.module';
import { PipesModule } from 'app/pipes/pipes.module';
import { DigitalClockComponent } from './digital-clock/digital-clock.component';

@NgModule({
  declarations: [
    DigitalClockComponent,
  ],
  imports: [
    CommonModule,
    FlatworldMaterialModule,
    PipesModule,
  ],
  exports: [
    DigitalClockComponent,
  ],
})
export class DigitalClockModule { }
