import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

const typeFormat = {
  blackboard: 'Blackboard',
  canvas: 'Canvas',
  d2l: 'D2L',
  moodle: 'Moodle',
  sakai: 'Sakai',
  halo: 'Halo',
};

@Pipe({
  name: 'ltiTypeFormat',
})
export class LtiTypeFormatPipe implements PipeTransform {
  transform(value: string): string {
    return typeFormat?.[value];
  }
}
