import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[appMin]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinDirective, multi: true}],
})
export class MinDirective implements Validator {
  @Input() appMin: number;

  validate(c: FormControl<number>): {[key: string]: any} {
    const v = c.value;
    return ( v < this.appMin) ? { appMin: true } : null;
  }
}
