import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { AuthenticationFacade } from './auth.facade';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authFacade: AuthenticationFacade) { }

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.authFacade.canActivate(next);
  }
}
