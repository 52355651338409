import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConfirmationDialogModule } from '../custom-components/confirmation-dialog.module';
import { FlatworldMaterialModule } from '../flatworld-material/flatworld.material.module';
import { Notifier, ToastNotifier } from './notifications';
import { DialogPrompter, Prompter } from './prompts';

@NgModule({
  imports: [
    CommonModule,
    FlatworldMaterialModule,
    ConfirmationDialogModule,
  ],
})
export class PromptersModule {
  static forRoot(): ModuleWithProviders<PromptersModule> {
    return {
      ngModule: PromptersModule,
      providers: [
        {
          provide: Notifier,
          useClass: ToastNotifier,
        },
        {
          provide: Prompter,
          useClass: DialogPrompter,
        },
      ],
    };
  }
}
