import { Injectable } from '@angular/core';
import { JsonApiModel, ModelType } from '@asteasolutions/angular2-jsonapi';
import { connectable, Observable, Subject } from 'rxjs';
import { Datastore } from './datastore/datastore.service';

@Injectable()
export class ModelInvalidationService {
  constructor(private datastore: Datastore) { }

  /**
   * This is an ugly hack for manual invalidation of JSON API models. It's here to avoid issues in
   * the JSON API datastore. Please use it only if you're desperate.
   */
  public invalidateModel<T extends JsonApiModel>(modelType: ModelType<T>, model): Observable<T> {
    const result: Observable<T> = this.datastore.findRecord(modelType, model.id);

    connectable(result, { connector: () => new Subject() }).connect();

    return result;
  }
}
