import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GradingType } from 'app/model/question';

@Component({
  selector: 'app-question-create-dialog',
  templateUrl: './question-create-dialog.component.html',
  styleUrls: ['./question-create-dialog.component.scss'],
})
export class QuestionCreateDialogComponent {
  public selectedGradingType: GradingType = 'auto';
  public supportsManualGrading: boolean;
  public supportsExcelGrading: boolean;

  constructor(
    public dialogRef: MatDialogRef<QuestionCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { supportsManualGrading: boolean; supportsExcelGrading: boolean },
  ) {
    this.supportsManualGrading = data.supportsManualGrading;
    this.supportsExcelGrading = data.supportsExcelGrading;
  }

  submit() {
    this.dialogRef.close({
      selectedGradingType: this.selectedGradingType,
    });
  }
}
