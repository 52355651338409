import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'app/custom-components/breadcrumb.module';
import { ApplicationNavigationTypeService } from '../application-navigation-type.service';
import { DirectivesModule } from '../custom-components/directives.module';
import { LoadingIndicatorModule } from '../custom-components/loading-indicator.module';
import { UserFirstNameModule } from '../custom-components/user-first-name.module';
import { FlatworldMaterialModule } from '../flatworld-material/flatworld.material.module';
import { ApplicationNavigationComponent } from './application-navigation/application-navigation.component';
import { DesktopFooterComponent } from './desktop-footer/desktop-footer.component';
import { GoogleTagsScriptDirective } from './google-tags-script.directive';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { TopBarComponent } from './top-bar/top-bar.component';

export interface INavigationModuleOptions {
  navigateToAdminPanel: () => void;
  navigateToDashboard: () => void;
  navigateToCatalog: () => void;
}

@NgModule({
  imports: [
    BreadcrumbModule,
    BrowserModule,
    CommonModule,
    FlatworldMaterialModule,
    UserFirstNameModule,
    RouterModule,
    DirectivesModule,
    LoadingIndicatorModule,
  ],
  declarations: [
    ApplicationNavigationComponent,
    DesktopFooterComponent,
    SideNavigationComponent,
    TopBarComponent,
    GoogleTagsScriptDirective,
  ],
  exports: [
    ApplicationNavigationComponent,
  ],
  providers: [
    ApplicationNavigationTypeService,
  ],
})
export class NavigationModule {
  public static forRoot(options: INavigationModuleOptions): ModuleWithProviders<NavigationModule> {
    return {
      ngModule: NavigationModule,
      providers: [
        {
          provide: 'navigateToAdminPanel',
          useValue: options.navigateToAdminPanel,
        }, {
          provide: 'navigateToDashboard',
          useValue: options.navigateToDashboard,
        }, {
          provide: 'navigateToCatalog',
          useValue: options.navigateToCatalog,
        },
      ],
    };
  }
}
