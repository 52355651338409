import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { combineLatestWith, map } from 'rxjs/operators';
import { BreadcrumbService } from './custom-components/breadcrumb/breadcrumb.service';

@Injectable()
export class TitleService {
  private _title$: Observable<string>;

  constructor(
    private breadcrumbService: BreadcrumbService) {

    this._title$ = this.breadcrumbService.breadcrumbs$.pipe(
      map(breadcrumbs => _.chain(breadcrumbs)
        .reject(['label', ''])
        .map('label')
        .last()
        .value()),
    );
  }

  public get shortTitle$(): Observable<string> {
    return this._title$;
  }

  public get title$(): Observable<string> {
    return this._title$.pipe(
      combineLatestWith(this.breadcrumbService.rootBreadcrumb$),
      map(([title, rootBreadcrumb]) => `${title} | ${rootBreadcrumb}`),
    );
  }
}
