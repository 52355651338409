import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Course } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'lti-courses',
})
export class LtiCourse extends Model {
  @Attribute() consumerUrl: string;
  @Attribute() ltiCourseId: string;
  @Attribute() consumerKey: string;
  @Attribute() ltiType: string;
  @Attribute() upgradedBookId: string;

  @BelongsTo() course: Course;
}
