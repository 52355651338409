import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  private timezone = moment.tz.guess(true);

  transform(value?: Date | string | number | moment.Moment, format?: string) {
    if (!value) { return null; }
    return moment.tz(value, this.timezone).format(format || 'MMMM D, YYYY [at] h:mm A z');
  }
}
