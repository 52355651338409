import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { Assignment, Course } from '../model/index';

type LtiEntity = Assignment | Course;

@Pipe({
  name: 'filterLtiEntities',
})
export class FilterLtiEntitiesPipe implements PipeTransform {
  transform(entities: Assignment[]): Assignment[];
  transform(entities: Course[]): Course[];
  transform(entities: LtiEntity[]): LtiEntity[] {
    return _.filter(entities, (e) => e && !e.isFromLti);
  }
}
