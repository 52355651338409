import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AuthService } from 'app/authentication/auth.service';
import { Course } from 'app/model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit {
  private static readonly CLOSE_TIMEOUT = 100;
  public courses$: Observable<Course[]>;
  public coursesMenuOpen: boolean;

  @Output() logout = new EventEmitter<void>();
  @Output() closeNav = new EventEmitter<void>();

  constructor( @Inject('navigateToAdminPanel') private navigateToAdminPanel: () => void,
               @Inject('navigateToDashboard') private navigateToDashboard: () => void,
               private authService: AuthService) {
  }

  ngOnInit() {
    this.courses$ = this.authService.currentUser.pipe(
      filter(user => !!user),
      map(user => {
        const courses = _.chain(user.enrolledCourses.concat(user.taughtCourses))
          .filter(course => !course.isFromLti)
          .value();

        return courses;
      }),
    );
  }

  public openAdminPanel() {
    this.navigateToAdminPanel();
  }

  public logoutButtonClick() {
    this.buttonClicked();
    this.logout.emit();
  }

  public dashboardButtonClick() {
    this.buttonClicked();
    this.navigateToDashboard();
  }

  public buttonClicked() {
    setTimeout(() => {
      this.closeNav.emit();
    }, SideNavigationComponent.CLOSE_TIMEOUT);
  }

  public showCoursesMenu(value: boolean) {
    this.coursesMenuOpen = value;
  }
}
