import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, filter, finalize, map, shareReplay, switchMap } from 'rxjs/operators';
import { LoadingWatch } from './loading-watch';

@Injectable()
export class HttpTrafficWatch extends LoadingWatch implements OnDestroy, HttpInterceptor {
  private _loading$: Observable<boolean>;
  private _count$: Observable<number>;
  private sync: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() {
    super();
    this._count$ = this.sync.asObservable().pipe(
      shareReplay());
    this._loading$ = this._count$.pipe(
      map(count => count > 0),
      distinctUntilChanged(),
      switchMap(loading => {
        const timeout: number = loading ? 1000 : 50;
        return of(loading).pipe(delay(timeout));
      }),
      shareReplay() );
  }

  public ngOnDestroy() {
    this.sync.complete();
  }

  public get count$(): Observable<number> {
    return this._count$;
  }

  public get loading$(): Observable<boolean> {
    return this._loading$;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<{}>> {
    this.up();
    return next.handle(req).pipe(
      filter(res => !!res.type),
      finalize(() => this.down()),
    );
  }

  private up() {
    this.change(1);
  }

  private down = () => {
    this.change(-1);
  };

  private change = (diff: 1 | -1) => {
    this.sync.next(this.sync.value + diff);
  };
}
