import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import moment from 'moment';
import type { Assignment, User } from './internal';
import { Model, Validation } from './model';

@JsonApiModelConfig({
  type: 'extensions',
})
export class Extension extends Model {
  @Attribute() dueTime: Date;
  @Attribute() endTime: Date;
  @Attribute() extraTime: number;

  @BelongsTo() assignment: Assignment;
  @BelongsTo() student: User;

  @Validation(['Due date', 'End date'], 'must be logical.') validTimes() {
    if (this.allowNull || !this.endTime) { return true; }

    return this.dueTime <= this.endTime;
  }

  @Validation('Due date', 'must be on or after the original due date.') validDueDate() {
    if (this.allowNull || !this.dueTime) { return true; }

    return moment(this.dueTime).isSameOrAfter(moment(this.assignment.dueTime), 'minute');
  }

  @Validation('End date', 'must be on or after the original end date.') validEndDate() {
    if (this.allowNull || !this.endTime) { return true; }

    return moment(this.endTime).isSameOrAfter(moment(this.assignment.endTime), 'minute');
  }

  @Validation(['End date'], 'must be at least 24 hours after extension due date if late penalty exists.') validEndDateWithLatePenalty() {
    if (this.allowNull || !this.assignment.lateSubmissionPenaltyPercent) { return true; }

    return moment(this.endTime).isSameOrAfter(moment(this.dueTime).add(1, 'd'));
  }

  @Validation('Extra time', 'or due date must be extended') extendedTimeOrDate() {
    return this.hasExtendedTime || this.hasExtendedDate;
  }

  get allowNull(): boolean {
    return !this.assignment.hasDueEndTime;
  }

  get hasExtendedDate(): boolean {
    for (const key of ['dueTime', 'endTime']) {
      if (!this[key] || !this.assignment[key]) { continue; }

      if (moment(this[key]).isAfter(moment(this.assignment[key]), 'minute')) {
        return true;
      }
    }
    return false;
  }

  get hasExtendedTime(): boolean {
    return this.extraTime > 0;
  }
}
