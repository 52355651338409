import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'app/authentication/auth.service';
import { Datastore } from 'app/datastore/datastore.service';
import { UnsubscribeOnDestroy } from 'app/decorators/unsubscribe-on-destroy.decorator';
import { Course } from 'app/model';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { BreadcrumbService, IBreadcrumb } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UnsubscribeOnDestroy
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$: Observable<IBreadcrumb[]>;
  hideBreadcrumbs$: Observable<boolean>;
  course: Course;
  isEducator: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private cd: ChangeDetectorRef,
    private datastore: Datastore,
  ) {
    this.subscriptions.push(this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) { this.initializeCourseData(); }
    }));
  }

  ngOnInit() {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$.asObservable();
    this.hideBreadcrumbs$ = this.breadcrumbService.hideBreadcrumbs$;
  }

  showBlueprintIcon(breadcrumb: IBreadcrumb): boolean {
    if (!breadcrumb || !this.course || !this.isEducator) { return false; }
    return (this.course.name === breadcrumb.label);
  }

  private courseMatchesRoute(courseFromRoute: Course): boolean {
    if (!courseFromRoute || !this.course) { return false; }
    return (courseFromRoute.id === this.course.id);
  }

  private initializeCourseData() {
    this.subscriptions.push(this.authService.currentUser.subscribe((user) => {
      const courseFromRoute = this.route.snapshot?.children?.[0]?.children?.[0]?.data?.course;

      if (this.courseMatchesRoute(courseFromRoute)) {
        this.course = this.datastore.peekRecord(Course, this.course.id);
        this.cd.detectChanges();
      } else {
        this.course = courseFromRoute;
      }

      this.isEducator = user && this.course && user.isEducator(this.course);
    }));
  }
}
