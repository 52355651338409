import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import { Model } from '../model';
import type { LearnosityQuestion } from './question';

@JsonApiModelConfig({
  type: 'learnosity/question-scores',
})
export class QuestionScore extends Model {
  @BelongsTo() learnosityQuestion: LearnosityQuestion;
  @Attribute() packet: ILearnosityItemsApiInitializationObject;
}
