import { Attribute, BelongsTo, HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import type {
  Assignment,
  AssignmentQuestion,
  Chapter,
  Course,
  LearnosityQuestion,
  User,
  Variant,
} from './internal';
import { Taggable } from './internal';

export interface IQuestionType {
  learnosityQuestionKind: string;
  displayValue: string;
}

export type GradingType = 'auto' | 'manual' | 'excel';

export const QUESTION_DIFFICULTIES = <const>['easy', 'medium', 'hard', 'basic', 'advanced'];
export type QuestionDifficulty = typeof QUESTION_DIFFICULTIES[number];

@JsonApiModelConfig({
  type: 'questions',
})
export class Question extends Taggable {
  @Attribute() name: string;
  @Attribute() createdAt: Date;
  @Attribute() sortPosition: number;
  @Attribute() sourceId: number;
  @Attribute() linkeeId: number;
  @Attribute() custom: boolean;
  @Attribute() graphingCalculator: boolean;
  @Attribute() difficulty: QuestionDifficulty;

  @Attribute({ serializedName: 'variantsCount' })
  assignmentVariantsCount: number;
  @Attribute() examVariantsCount: number;
  @Attribute() isExam: boolean;
  @Attribute() isUsedInAssignments: boolean;
  @Attribute() canBeDeleted: boolean;
  @Attribute() gradingType: GradingType;

  @Attribute() previousQuestionId?: string;
  @Attribute() nextQuestionId?: string;

  @HasMany() assignments: Assignment[];
  @HasMany() copies: Question[];
  @HasMany() authors: User[];
  @HasMany() assignmentQuestions: AssignmentQuestion[];
  @HasMany() variants: Variant[];
  @BelongsTo() chapter: Chapter;
  @BelongsTo() course: Course;
  @BelongsTo() original: Question;

  @HasMany({ key: 'learnosity::Questions' })
  'learnosity::Questions': LearnosityQuestion[];
  @HasMany({ key: 'learnosity::ExamQuestions' })
  'learnosity::ExamQuestions': LearnosityQuestion[];
  @HasMany({ key: 'learnosity::NonExamQuestions' })
  'learnosity::NonExamQuestions': LearnosityQuestion[];

  get learnosityQuestions(): LearnosityQuestion[] {
    return _.union(this['learnosity::Questions'],
                   this['learnosity::ExamQuestions'],
                   this['learnosity::NonExamQuestions']);
  }

  addLearnosityQuestion(learnosityQuestion: LearnosityQuestion) {
    this['learnosity::Questions'].push(learnosityQuestion);
  }

  removeLearnosityQuestion(learnosityQuestion: LearnosityQuestion) {
    _.pull(this['learnosity::Questions'], learnosityQuestion);
  }

  get variantsCount(): number {
    switch (this._includedLearnosityQuestions) {
      case 'exam':
        return this.examVariantsCount;
      case 'nonExam':
        return this.assignmentVariantsCount;
      default:
        return this.assignmentVariantsCount + this.examVariantsCount;
    }
  }

  set variantsCount(value: number) {
    this.assignmentVariantsCount = value;
  }

  set includedLearnosityQuestions(value: string) {
    this._includedLearnosityQuestions = value;
  }

  get learnosityQuestion(): LearnosityQuestion {
    return _.first(this.learnosityQuestions);
  }

  get learnosityQuestionKind(): string {
    return this.learnosityQuestion?.kind;
  }

  get parts(): number {
    return this._parts || 1;
  }

  set parts(value: number) {
    this._parts = value;
  }

  get hasMultipleParts(): boolean {
    return this.parts > 1;
  }

  get hasVariability(): boolean {
    return this.variantsCount > 1;
  }

  get bookPublished(): boolean {
    return this.chapter.book.published;
  }

  get canRemoveVariations(): boolean {
    return this.learnosityQuestions.length > 1;
  }

  get canMarkVariantsForExam(): boolean {
    return !this.isUsedInAssignments;
  }

  get isCustom(): boolean {
    return this.custom;
  }

  get hasOriginal(): boolean {
    return !!this.original;
  }

  get nameWithoutNumericSuffix(): string {
    if (this.hasOriginal) {
      return this.original.nameWithoutNumericSuffix;
    }

    const suffix = this.numericSuffixString;

    if (!suffix) {
      return this.name;
    }

    return this.name.slice(0, -suffix.length);
  }

  get numericSuffix(): number {
    return parseInt(this.numericSuffixString, 10);
  }

  get ordinal(): number {
    if ( !_.isNil(this.sortPosition) ) {
      return this.sortPosition;
    }
    if ( this.hasOriginal ) {
      return this.original.ordinal;
    }
    return undefined;
  }

  get manuallyGraded() {
    return this.gradingType === 'manual';
  }

  get autoGraded() {
    return this.gradingType === 'auto';
  }

  public get excelGrader() {
    return this.gradingType === 'excel';
  }

  private get numericSuffixString(): string {
    if (this.hasOriginal) {
      return this.original.numericSuffixString;
    }

    return this.numericSuffixInternal;
  }

  private get numericSuffixInternal(): string {
    const match = /(\d+)$/.exec(this.name);
    return match ? match[1] : undefined;
  }
}
