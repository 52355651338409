import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { LocationService } from './authentication/location.service';
import { Course, Invitation } from './model';
import { Notifier } from './prompters/notifications';

@Injectable()
export class ClipboardService {

  constructor(
    private clipboard: Clipboard,
    private location: LocationService,
    private notifier: Notifier) { }

  copyInvitationLinkToClipboard(invitation: Invitation, course: Course) {
    const link = this.generateInvitationLink(invitation);
    this.clipboard.copy(link);
    this.notifier.onSuccess(`The invitation link for ${course.name} was successfully copied to your clipboard.`);
  }

  generateInvitationLink(invitation: Invitation): string {
    return `${this.location.root}/c/${invitation.token}`;
  }
}
