import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Cookies } from 'app/constants';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationStorage } from './auth.service';
import { LocationService } from './location.service';
import { IDestinationOptions, ISourceOptions } from './return-options';

@Injectable()
export class AuthenticationFacade {
  constructor(private authStorage: AuthenticationStorage,
              private router: Router,
              private location: LocationService,
              private cookieService: CookieService,
              @Inject('mountPath') private mountPath: string,
  ) { }

  public canActivate(_next: ActivatedRouteSnapshot): boolean | UrlTree {
    this.handleForcedAuthentication();

    if (!this.authStorage.isAuthenticated()) {
      const returnOptions = this.createDestinationOptions();
      return this.loginUrl(returnOptions);
    }

    return true;
  }

  public logout(returnBack: boolean = false) {
    const sourceOptions = this.createSourceOptions(returnBack);
    const logoutUrl = this.logoutUrl(sourceOptions);
    this.router.navigateByUrl(logoutUrl);
  }

  private createDestinationOptions(): IDestinationOptions {
    const protocol = this.location.protocol;
    const host = this.location.host;
    const match = new RegExp(`${protocol}//${host}/(.*)$`).exec(this.location.href);
    const pathSegment = match[1];

    const urlTree = this.router.parseUrl(pathSegment);
    const params = JSON.stringify(urlTree.queryParams);
    urlTree.queryParams = {};
    const path = urlTree.toString();

    return {
      path,
      params,
    };
  }

  private createSourceOptions(returnBack: boolean = false): ISourceOptions {
    const url = returnBack ? this.location.href : this.location.root;
    return {
      url,
    };
  }

  private loginUrl(returnOptions: IDestinationOptions): UrlTree {
    const urlTree = this.router.parseUrl(this.loginPath);
    urlTree.queryParams = returnOptions;
    return urlTree;
  }

  private logoutUrl(returnOptions: ISourceOptions): UrlTree {
    const urlTree = this.router.parseUrl(`${this.mountPath}/logout`);
    urlTree.queryParams = returnOptions;
    return  urlTree;
  }

  private handleForcedAuthentication() {
    // The app is forced to (re)authenticate if the server has set the need_authentication cookie.
    // This cookie is usually set in a previous request, before this app has started loading,
    // and acts as a one-shot message from the server to the client, i.e. it should be discarded
    // after it is read.
    const needAuthCookie = this.cookieService.get(Cookies.NEED_AUTHENTICATION);
    this.cookieService.delete(Cookies.NEED_AUTHENTICATION);

    const isAuthForced = /true/i.test(needAuthCookie);
    const isOnLoginPage = this.router.isActive(this.loginPath, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });

    // Even if authentication is forced, we shouldn't clean up the login info if we are
    // currently on the login page (and trying to navigate somewhere else).
    if (isAuthForced && !isOnLoginPage) {
      this.authStorage.cleanPracticeLoginInfo();
    }
  }

  private get loginPath() {
    return `${this.mountPath}/login`;
  }
}
