import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlatworldMaterialModule } from '../flatworld-material/flatworld.material.module';
import { PipesModule } from '../pipes/pipes.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog.component';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    UnsavedChangesDialogComponent,
  ],
  imports: [
    FlatworldMaterialModule,
    CommonModule,
    PipesModule,
  ],
  exports: [
    ConfirmationDialogComponent,
    UnsavedChangesDialogComponent,
  ],
})
export class ConfirmationDialogModule { }
