import { AfterViewInit, Directive, ElementRef,
  HostListener, Input } from '@angular/core';

const SCROLL_THRESHOLD = 50;
const SHADOW_MIN_OFFSET = -5;
const SHADOW_MAX_OFFSET = 4;

@Directive({
  selector: '[appStickyShadowOnTableScroll]',
})
export class StickyShadowOnTableScrollDirective implements AfterViewInit {
  @Input('appStickyShadowOnTableScroll') classSelectors: string[];

  private elements: HTMLElement[];

  constructor(private element: ElementRef) { }

  ngAfterViewInit() {
    const selectors = this.classSelectors.map(classSelector => `.mat-table-sticky.${classSelector}`);

    this.elements = this.element.nativeElement.querySelectorAll(selectors);
  }

  @HostListener('scroll', ['$event.target.scrollLeft']) onScroll(scrollLeft) {
    this.applyShadow(scrollLeft);
  }

  private applyShadow(scrollPosition: number) {
    const offsetPercentage = Math.min(scrollPosition, SCROLL_THRESHOLD) / SCROLL_THRESHOLD;
    const xOffset = SHADOW_MIN_OFFSET + (offsetPercentage * (SHADOW_MAX_OFFSET - SHADOW_MIN_OFFSET));
    const boxShadow = `${xOffset}px 5px 5px 1px rgba(0, 0, 0, 0.16)`;

    this.elements.forEach(element => element.style.boxShadow = boxShadow);
  }
}
