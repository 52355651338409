import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { INamer, Name } from './custom-components/breadcrumb.module';
import { Datastore } from './datastore/datastore.service';
import { Assignment, Book, Chapter, Course, Question } from './model';
import { Model } from './model/model';
import { RouteTraversal } from './route-traversal';

export class BaseNamer<T extends Model> implements INamer {
  constructor(private datastore: Datastore,
              private type,
              private paramName: string) { }
  public resolve(snapshot: ActivatedRouteSnapshot): Observable<Name> {
    const id = RouteTraversal.traverseSnapshotParams(snapshot, this.paramName);
    const loadedModel = this.datastore.peekRecord(this.type, id);

    if (loadedModel) {
      return of(loadedModel.name);
    }

    return this.datastore.query(this.type, { filter: { id } }).pipe(
      map((model: T[]) => model[0].name),
    );
  }
}

@Injectable()
export class BookNamer extends BaseNamer<Book> {
  constructor(datastore: Datastore) {
    super(datastore, Book, 'bookId');
  }
}

@Injectable()
export class ChapterNamer extends BaseNamer<Chapter> {
  constructor(datastore: Datastore) {
    super(datastore, Chapter, 'chapterId');
  }
}

@Injectable()
export class QuestionNamer extends BaseNamer<Question> {
  constructor(datastore: Datastore) {
    super(datastore, Question, 'questionId');
  }
}

@Injectable()
export class CourseNamer extends BaseNamer<Course> {
  constructor(datastore: Datastore) {
    super(datastore, Course, 'courseId');
  }
}

@Injectable()
export class AssignmentNamer extends BaseNamer<Assignment> {
  constructor(datastore: Datastore) {
    super(datastore, Assignment, 'assignmentId');
  }
}

@Injectable()
export class LtiCourseNamer implements INamer {
  public resolve(snapshot: ActivatedRouteSnapshot): Observable<Name> {
    const name = snapshot.parent.data['course'].name || 'Course';
    return of(name);
  }
}
