<div class="mat-body-2 footer-links">
  <ul>
    <li>
      <a href="https://catalog.flatworldknowledge.com/browse_catalog"
         target="_blank">CATALOG</a>
    </li>
    <li>
      <a href="https://catalog.flatworldknowledge.com/customer_support"
         target="_blank">CONTACT & SUPPORT</a>
    </li>
    <li>
      <a href="https://catalog.flatworldknowledge.com/instructors/dashboard"
         target="_blank">TERMS OF USE</a>
    </li>
    <li>
      <a href="https://catalog.flatworldknowledge.com/privacy"
         target="_blank">PRIVACY</a> &
      <a href="https://catalog.flatworldknowledge.com/ip_dmca"
          target="_blank">IP POLICIES</a>
    </li>
  </ul>
  <div>
    <span>© FlatWorld {{currentYear()}}</span>
  </div>
</div>
