<h2 mat-dialog-title>
  <span>{{ title || 'Unsaved Changes' }}</span>
  <button *ngIf="!disableClose"
          mat-icon-button
          mat-dialog-close="cancel"
          aria-label="Close dialog">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content>
  <span *ngIf="question; else defaultQuestion" [innerHTML]="question | sanitize"></span>
  <ng-template #defaultQuestion>
    <span>The changes you made haven't been saved. Do you want to save them before you leave?</span>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button
          mat-dialog-close="discard"
          color="primary">
    {{ discard || 'Discard' }}
  </button>

  <button mat-raised-button
          mat-dialog-close="save"
          color="primary"
          cdkFocusInitial>
    {{ save || 'Save' }}
  </button>
</mat-dialog-actions>
