import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Attempt } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'lti-attempts',
})
export class LtiAttempt extends Model {
  @Attribute() lisResultSourcedId: string;
  @Attribute() lisBasicOutcomeUrl: string;
  @BelongsTo() attempt: Attempt;
}
