import { Pipe, PipeTransform } from '@angular/core';
import { keys } from 'lodash';

@Pipe({
  name: 'keys',
})
export class KeysPipe implements PipeTransform {

  transform(obj: any, _args?: any): any {
    if (!obj) {
      return [];
    }

    return keys(obj);
  }
}
