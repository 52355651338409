<ng-container *ngIf="!(hideBreadcrumbs$ | async)">
  <mat-toolbar *ngIf="(breadcrumbs$ | async); let breadcrumbs">
    <div class="breadcrumb" *ngFor="let breadcrumb of breadcrumbs; first as first">
      <a [routerLink]="[breadcrumb.url]" [matTooltip]="!first ? breadcrumb.label : ''">
        <app-blueprint-icon [course]="course" *ngIf="showBlueprintIcon(breadcrumb)"></app-blueprint-icon>
        {{ breadcrumb.label }}
      </a>

      <mat-icon class="right-arrow">keyboard_arrow_right</mat-icon>
    </div>
  </mat-toolbar>
</ng-container>
