import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  set(key: string, value: string | number) {
    if (value) {
      window.localStorage.setItem(key, `${value}`);
    } else {
      window.localStorage.removeItem(key);
    }
  }

  get(key: string): string {
    return window.localStorage.getItem(key);
  }
}
