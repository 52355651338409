<mat-toolbar class="top-bar">
  <button mat-icon-button
          class="menu-button"
          (click)="menuButtonClick()"
          aria-label="Main navigation"
          aria-haspopup="true" 
          [attr.aria-expanded]="opened">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="heading">
    {{ titleService.shortTitle$ | async }}
  </div>
</mat-toolbar>
<app-loading-bar></app-loading-bar>