import { HttpHeaders } from '@angular/common/http';
import { Attribute, BelongsTo, HasMany, JsonApiDatastore, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import { FormatDatePipe } from 'app/pipes/format-date.pipe';
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import type {
  AssignmentAggregation,
  AssignmentPerQuestionScoresReport,
  AssignmentQuestion,
  Attempt,
  Book,
  Course,
  EducatorAssignmentPreview,
  EducatorAssignmentReport,
  EducatorAssignmentSessionsReport,
  EducatorDetailsAssignmentReport,
  Exemption,
  Extension,
  IReportable,
  LtiAssignment,
  Question,
  StudentAssignmentReport,
} from './internal';
import {
  Model,
  Validate,
  Validation,
} from './model';

export type AssignmentShowEnum = 'never' | 'after_date' | 'always';
export type AssignmentStatus = 'draft' | 'active';
export type AssignmentScoringType = 'highest' | 'latest';
export type AssignmentShowDetailedReport = AssignmentShowEnum;
export type AssignmentShowSolutions = AssignmentShowEnum;
export type AssignmentShowCorrectAnswers = AssignmentShowEnum;
export type AssignmentShowGrades = AssignmentShowEnum;
export type AssignmentType = 'assignment' | 'exam';

type LearnosityQuestionsType = 'all' | 'exam' | 'nonExam';
interface IQuestionFilter { 'variants.exam': boolean }

const END_OF_DAY_IN_ONE_WEEK = (m: moment.Moment): Date => (
  m.add(1, 'week').hour(23).startOf('hour').add(59, 'minutes').toDate()
);

const D2L_MAX_NAME_LENGTH = 128;
const DUE_SOON_SECONDS_THRESHOLD = 5400; // 90 minutes
const NULLABLE_TIME_ATTRIBUTES = ['_dueTime', '_endTime'];

const DATE_SUFFIX_REGEX = /\(\d{1,2}\/\d{1,2}\/\d{4}\)$/;
const SINGLE_COPY_SUFFIX_REGEX = /(?:[^Copy]) Copy$/;
const MULTIPLE_COPY_SUFFIX_REGEX = /(Copy\s?){2,}$/;
const COPY_WITH_DATE_SUFFIX_REGEX = /Copy \(\d{1,2}\/\d{1,2}\/\d{4}\)/;

@JsonApiModelConfig({
  type: 'assignments',
})
export class Assignment extends Model implements IReportable {
  @Attribute() name: string;

  @Validate.Presence()
  @Attribute() startTime: Date;

  @Attribute() dueTime: Date;
  @Attribute() endTime: Date;

  @Attribute() status: AssignmentStatus;
  @Attribute() slug: string;
  @Attribute() blueprint: boolean;
  @Attribute() isFromBlueprint: boolean;
  @Attribute() isFromLti: boolean;
  @Attribute() kind: AssignmentType;
  @Attribute() timeLimit: number;
  @Attribute() attemptsLimit: number;
  @Attribute() currentAttemptsLimit: number;
  @Attribute() showHints: boolean;
  @Attribute() showHintsForMcq: boolean;
  @Attribute() hintsLimit: number;
  @Attribute() applyHintsLimit: boolean;
  @Attribute() showChapterLink: boolean;
  @Attribute() showVideoLink: boolean;
  @Attribute() showGraphingCalculatorLink: boolean;
  @Attribute() lateSubmissionPenaltyPercent: number;
  @Attribute() currentLateSubmissionPenaltyPercent: number;
  @Attribute() repeatSubmissionPenaltyPercent: number;
  @Attribute() currentRepeatSubmissionPenaltyPercent: number;
  @Attribute() randomizeQuestionsPerStudent: boolean;
  @Attribute() randomizeQuestionsPerAttempt: boolean;
  @Attribute() sourceId: string;
  @Attribute() scoringType: AssignmentScoringType;
  @Attribute() requireAccessCode: boolean;
  @Attribute() accessCodeToken: string;
  @Attribute() publishable: boolean;
  @Attribute() quickCreated: boolean;
  @Attribute() hasManualGrading: boolean;
  @Attribute() hasVariations: boolean;
  @Attribute() hasNotGradedAttempts: boolean;
  @Attribute() secondsUntilTotalEndTime: number;
  @Attribute() submissions: number;
  @Attribute() lastAttemptSubmittedAt: Date;

  @Attribute() showGrades: AssignmentShowGrades;
  @Attribute() showGradesAfter: Date;
  @Attribute() showDetailedReport: AssignmentShowDetailedReport;
  @Attribute() showDetailedReportAfter: Date;
  @Attribute() showCorrectAnswers: AssignmentShowCorrectAnswers;
  @Attribute() showCorrectAnswersAfter: Date;
  @Attribute() showSolutions: AssignmentShowSolutions;
  @Attribute() showSolutionsAfter: Date;
  @Attribute() showSolutionsDuringAttempt: boolean;
  @Attribute() showSolutionVideoDuringAttempt: boolean;

  @BelongsTo({
    key: 'learnosity::StudentAssignmentReport',
  })
  'learnosity::StudentAssignmentReport': StudentAssignmentReport;

  get studentReport(): StudentAssignmentReport {
    return this['learnosity::StudentAssignmentReport'];
  }

  set studentReport(report: StudentAssignmentReport) {
    this['learnosity::StudentAssignmentReport'] = report;
  }

  @BelongsTo({
    key: 'learnosity::EducatorAssignmentReport',
  })
  'learnosity::EducatorAssignmentReport': EducatorAssignmentReport;

  get educatorReport(): EducatorAssignmentReport {
    return this['learnosity::EducatorAssignmentReport'];
  }

  set educatorReport(report: EducatorAssignmentReport) {
    this['learnosity::EducatorAssignmentReport'] = report;
  }

  @BelongsTo({
    key: 'learnosity::EducatorAssignmentPreview',
  })
  'learnosity::EducatorAssignmentPreview': EducatorAssignmentPreview;

  get educatorPreview(): EducatorAssignmentPreview {
    return this['learnosity::EducatorAssignmentPreview'];
  }

  @BelongsTo({
    key: 'learnosity::EducatorDetailsAssignmentReport',
  })
  'learnosity::EducatorDetailsAssignmentReport': EducatorDetailsAssignmentReport;

  get educatorDetailsAssignmentReport(): EducatorDetailsAssignmentReport {
    return this['learnosity::EducatorDetailsAssignmentReport'];
  }

  @BelongsTo({
    key: 'learnosity::EducatorAssignmentSessionsReport',
  })
  'learnosity::EducatorAssignmentSessionsReport': EducatorAssignmentSessionsReport;

  get educatorAssignmentSessionsReport(): EducatorAssignmentSessionsReport {
    return this['learnosity::EducatorAssignmentSessionsReport'];
  }

  @BelongsTo({
    key: 'learnosity::AssignmentPerQuestionScoresReport',
  })
  'learnosity::AssignmentPerQuestionScoresReport': AssignmentPerQuestionScoresReport;

  get assignmentPerQuestionScoresReport(): AssignmentPerQuestionScoresReport {
    return this['learnosity::AssignmentPerQuestionScoresReport'];
  }

  @BelongsTo() course: Course;
  @BelongsTo() ltiAssignment: LtiAssignment;
  @HasMany() questions: Question[];
  @HasMany() attempts: Attempt[];
  @HasMany() assignmentQuestions: AssignmentQuestion[];
  @HasMany() extensions: Extension[];
  @HasMany() exemptions: Exemption[];

  @BelongsTo() assignmentAggregation: AssignmentAggregation;

  constructor(_datastore: JsonApiDatastore, data?: any) {
    super(_datastore, data);

    this.initializeAllowLateSubmissions();

    this._datesEnabled = this.hasDueEndTime;
  }

  save(params?: any, headers?: HttpHeaders): Observable<this> {
    this._allowLateSubmissionsChanged = false;
    return super.save(params, headers);
  }

  @Validation('Name', 'cannot have an empty name.') hasName() {
    return !!this.name;
  }

  @Validation(['Due date'], 'cannot be before start date.') validDueDate() {
    if (!this.hasDueTime) {
      return true;
    }

    return this.startTime <= this.dueTime;
  }

  @Validation(['Due date'], 'cannot be after the due date of any extensions.') validDueDateWithExtensions() {
    if (!this.hasDueTime || _.isEmpty(this.extensions)) {
      return true;
    }

    return !_.some(this.extensions, e => e.dueTime < this.dueTime);
  }

  @Validation(['End date'], 'Late submissions cannot end before due date.') validEndDate() {
    if (!this.hasEndTime) {
      return true;
    }

    return this.dueTime <= this.endTime;
  }

  @Validation(['End date'], 'must be at least 24 hours after due date if late penalty exists.') validEndDateWithLatePenalty() {
    if (!this.hasEndTime || !this.lateSubmissionPenaltyPercent) {
      return true;
    }

    return moment(this.endTime).isSameOrAfter(this.defaultEndTime);
  }

  @Validation(['End date'], 'cannot be after the end date of any extensions.') validEndDateWithExtensions() {
    if (!this.hasEndTime || this.isExam || _.isEmpty(this.extensions)) {
      return true;
    }

    return !_.some(this.extensions, e => e.endTime < this.endTime);
  }

  @Validation(['Time limit'], 'cannot end after due date.') validTimeLimit() {
    if (!this.hasTimeLimit || !this.hasDueTime) {
      return true;
    }

    return moment(this.startTime).add(this.timeLimit, 'm').toDate() <= this.dueTime;
  }

  get book(): Book {
    return this.course?.book;
  }

  get hasSolution(): boolean {
    return this.book.hasSolution;
  }

  get hasVideo(): boolean {
    return this.book.hasVideo;
  }

  get hasGraphing(): boolean {
    return this.book.hasGraphing;
  }

  get hasSolutionVideo(): boolean {
    return this.book.hasSolutionVideo;
  }

  get hasDueEndTime(): boolean {
    return this.hasDueTime && this.hasEndTime;
  }

  get hasAttempts(): boolean {
    return !_.isEmpty(this.attempts);
  }

  get hasStartedAttempts(): boolean {
    return _.some(this.attempts, ['status', 'started']);
  }

  get hasSubmittedAttempts(): boolean {
    return _.some(this.attempts, ['status', 'submitted']);
  }

  get hasStartedOrSubmittedAttempts(): boolean {
    return this.hasStartedAttempts || this.hasSubmittedAttempts;
  }

  get hasAttemptsLimitExemption(): boolean {
    return _.some(this.exemptions, 'hasAttemptsLimit');
  }

  get hasRandomization(): boolean {
    return this.randomizeQuestionsPerStudent || this.randomizeQuestionsPerAttempt;
  }

  get hasCurrentSubmissionPenalty(): boolean {
    return this.currentLateSubmissionPenaltyPercent > 0 || this.currentRepeatSubmissionPenaltyPercent > 0;
  }

  get isDueSoon(): boolean {
    const secondsRemaining = this.secondsUntilNextDeadline;
    return !_.isNil(secondsRemaining)
      ? secondsRemaining <= DUE_SOON_SECONDS_THRESHOLD
      : false;
  }

  get isExam(): boolean {
    return this.kind === 'exam';
  }

  get isBlueprintWithCopies(): boolean {
    return this.blueprint && this.course.hasCopies;
  }

  get isFromLtiBlueprint(): boolean {
    return this.isFromLti && this.isFromBlueprint;
  }

  get isFromLtiWithDates(): boolean {
    return this.course.isFromLti && this.canAlterDates;
  }

  get overdue(): boolean {
    return moment().isAfter(moment(this.dueTime)) && !this.expired;
  }

  get expired(): boolean {
    return moment().isAfter(moment(this.endTime));
  }

  get hasExtensions(): boolean {
    return !_.isEmpty(this.extensions);
  }

  get hasExemptions(): boolean {
    return !_.isEmpty(this.exemptions);
  }

  get hasTimeLimit(): boolean {
    return this.timeLimit > 0;
  }

  get hasExtendedDate(): boolean {
    return _.some(this.extensions, (extension) => (
      moment(extension.dueTime).isAfter(moment(this.dueTime)) ||
      moment(extension.endTime).isAfter(moment(this.endTime))
    ));
  }

  get hasExtendedTimeLimit(): boolean {
    return _.some(this.extensions, 'extraTime');
  }

  get hasRemainingTime(): boolean {
    return this.hasDueEndTime && !_.isNil(this.secondsUntilTotalEndTime);
  }

  get extensionOverdue(): boolean {
    if (!this.hasExtensions) {
      return this.overdue;
    }

    return moment().isAfter(moment(this.extensions[0].dueTime)) && !this.extensionExpired;
  }

  get extensionExpired(): boolean {
    if (!this.hasExtensions) {
      return this.expired;
    }

    return moment().isAfter(moment(this.extensions[0].endTime));
  }

  get totallyOverdue(): boolean {
    return this.extensionOverdue;
  }

  get totallyExpired(): boolean {
    return this.extensionExpired;
  }

  get defaultDueTime(): Date {
    const startTime = this.startTime ? moment(this.startTime) : moment();
    return END_OF_DAY_IN_ONE_WEEK(startTime);
  }

  get defaultEndTime(): Date {
    return moment(this.dueTime).add(1, 'd').toDate();
  }

  get totalDueTime(): Date {
    if (!this.hasExtensions) { return this.dueTime; }

    return _.chain(this.extensions)
      .map('dueTime')
      .concat(this.dueTime)
      .max()
      .value();
  }

  get totalEndTime(): Date {
    if (!this.hasExtensions) { return this.endTime; }

    return _.chain(this.extensions)
      .map('endTime')
      .concat(this.endTime)
      .max()
      .value();
  }

  get totalTimeLimit(): number {
    if (!this.hasExtensions) { return this.timeLimit; }

    return this.timeLimit + (this.extensions[0].extraTime || 0);
  }

  get nextDeadline(): Date {
    if (this.hasExtensions) {
      if (this.extensionExpired) {
        return this.extensions[0].endTime;
      }
      if (this.extensionOverdue) {
        return this.extensions[0].endTime;
      }
      return this.extensions[0].dueTime;
    }
    if (this.expired) {
      return this.endTime;
    }
    if (this.overdue) {
      return this.endTime;
    }
    return this.dueTime;
  }

  get secondsUntilNextDeadline(): number {
    if (!this.hasRemainingTime) { return null; }

    return this.totallyOverdue
      ? this.secondsUntilTotalEndTime
      : this.secondsUntilTotalDueTime;
  }

  get secondsUntilTotalDueTime(): number {
    if (!this.hasRemainingTime) { return null; }

    const totalEndDueDifference = moment(this.totalEndTime).diff(moment(this.totalDueTime), 'seconds');

    return this.secondsUntilTotalEndTime - totalEndDueDifference;
  }

  get notStarted(): boolean {
    return this.hasDueEndTime && moment().isBefore(moment(this.startTime));
  }

  get canBeEdited(): boolean {
    return !this.published;
  }

  get canBePublished(): boolean {
    return !this.published;
  }

  get canBeDeleted(): boolean {
    return this.isPersisted;
  }

  get canBeUnpublished(): boolean {
    return this.status === 'active' && !this.isFromBlueprint && !this.overdue && !this.expired && !this.hasAttempts;
  }

  get canAddExtensions(): boolean {
    return this.published && ((this.canAlterDates && this.hasDueEndTime) || this.hasTimeLimit);
  }

  get canAddExemptions(): boolean {
    return this.published && !!(this.attemptsLimit || this.lateSubmissionPenaltyPercent);
  }

  get canEditAccommodations(): boolean {
    return this.hasExtensions || this.hasExemptions || this.canAddExtensions || this.canAddExemptions;
  }

  get canSyncGradesWithLms(): boolean {
    return this.isFromLti && this.showGradesNow;
  }

  get accommodationsLabel(): string {
    let label = this.canAddExtensions ? 'time extensions' : '';
    if (this.canAddExemptions) {
      label += `${label.length ? ' and ' : ''}submission requirements`;
    }
    return label;
  }

  get published(): boolean {
    return this.status === 'active';
  }

  set published(published: boolean) {
    this.status = published ? 'active' : 'draft';
  }

  get lmsLabel(): string {
    return this.isFromLti ? (this.course?.lmsType ?? 'LMS') : '';
  }

  get nameMaxLength(): number {
    return this.lmsLabel === 'D2L' ? D2L_MAX_NAME_LENGTH : null;
  }

  get attemptsOrderBy(): string {
    if (this.scoringType === 'highest') {
      return 'finalPenalizedScorePercent';
    }

    return 'submittedAt';
  }

  get lastAttempt(): Attempt {
    if (!this.attempts?.length) { return; }

    return _.chain(this.attempts)
      .filter(['status', 'submitted'])
      .orderBy(this.attemptsOrderBy)
      .last()
      .value();
  }

  get grade(): string {
    const { lastAttempt } = this;

    if (!lastAttempt) {
      return '-';
    }

    if (lastAttempt.needsManualGrading) {
      return 'Not Graded';
    }

    if (!lastAttempt.gradeAvailable) {
      return lastAttempt.gradeUnavailableLabel;
    }

    return `${lastAttempt.penalizedScorePercent}%`;
  }

  get showDetailedReportNow(): boolean {
    if (!this.hasSubmittedAttempts) {
      return false;
    }

    if (this.showDetailedReport === 'always') {
      return true;
    }

    return this.showDetailedReport === 'after_date' &&
      this.showDetailedReportAfter &&
      moment(this.showDetailedReportAfter).isBefore(moment());
  }

  get showDetailedReportInFuture(): boolean {
    return this.showDetailedReport === 'after_date' &&
      this.showDetailedReportAfter &&
      moment(this.showDetailedReportAfter).isAfter(moment());
  }

  get showSolutionsNow(): boolean {
    if (!this.hasSolution || this.showSolutions === 'never') {
      return false;
    }

    const showSolutions = this.showSolutions === 'always' || (
      this.showSolutionsAfter && moment(this.showSolutionsAfter).isBefore(moment())
    );

    return showSolutions && this.showDetailedReportNow;
  }

  get showCorrectAnswersNow(): boolean {
    if (!this.hasSubmittedAttempts) {
      return false;
    }

    const showCorrectAnswers = this.showCorrectAnswers === 'always' || (
      this.showCorrectAnswersAfter && moment(this.showCorrectAnswersAfter).isBefore(moment())
    );

    return showCorrectAnswers && this.showDetailedReportNow;
  }

  get showGradesNow(): boolean {
    if (!this.hasSubmittedAttempts) {
      return false;
    }

    if (this.showGrades === 'always') {
      return true;
    }

    return this.showGrades === 'after_date' &&
      moment(this.showGradesAfter).isBefore(moment());
  }

  get showGradesInFuture(): boolean {
    return this.showGrades === 'after_date' &&
      this.showGradesAfter &&
      moment(this.showGradesAfter).isAfter(moment());
  }

  get canAlterDates(): boolean {
    return this.course.supportsAssignmentDates;
  }

  get canAlterAllowLateSubmissions(): boolean {
    return !this.published || !this._allowLateSubmissions || this._allowLateSubmissionsChanged;
  }

  get canAlterRepeatSubmissionPenaltyPercent(): boolean {
    if (!this.published) {
      return true;
    }
    return this.attemptsLimit !== 1;
  }

  get canAlterScoringType(): boolean {
    return !this.hasManualGrading && !(this.isFromLti && this.published && this.hasSubmittedAttempts);
  }

  get allowLateSubmissions(): boolean {
    return this._allowLateSubmissions;
  }

  set allowLateSubmissions(value: boolean) {
    this._allowLateSubmissions = value;
    this._allowLateSubmissionsChanged = true;

    if (value) {
      this.endTime = this.defaultEndTime;
    } else {
      this.endTime = this.dueTime;
      this.lateSubmissionPenaltyPercent = 0;
    }
  }

  initializeAllowLateSubmissions() {
    this._allowLateSubmissions = this.hasDueEndTime && (this.dueTime.getTime() !== this.endTime.getTime());
    this._allowLateSubmissionsChanged = false;
  }

  get datesEnabled(): boolean {
    return this._datesEnabled;
  }

  set datesEnabled(value: boolean) {
    this._datesEnabled = value;
  }

  setAttemptsLimit(attempts: number) {
    if (attempts === 1 && !this.published) {
      this.repeatSubmissionPenaltyPercent = 0;
    }
  }

  setDefaultStart(inEmbedMode = false) {
    this.startTime = this.defaultStartTime(inEmbedMode);
  }

  setDefaultDueEnd(datesEnabled = false) {
    this.dueTime = this.defaultDueTime;
    this.endTime = this.defaultEndTime;
    this.datesEnabled = datesEnabled;
  }

  setNullDueEnd() {
    this.dueTime = new Date(NaN);
    this.endTime = new Date(NaN);
  }

  set startDate(value: Date) {
    this.changeDateOnly(value, 'startTime');
    this.updateDueTime();
  }

  setStartTime(date: Date) {
    this.startTime = moment.utc(date).toDate();
  }

  set dueDate(value: Date) {
    this.changeDateOnly(value, 'dueTime');
    this.updateEndTime();
  }

  setDueTime(date: Date) {
    this.dueTime = moment.utc(date).toDate();
    this.updateEndTime();
  }

  set endDate(value: Date) {
    this.changeDateOnly(value, 'endTime');
  }

  setEndTime(date: Date) {
    this.endTime = moment.utc(date).toDate();
  }

  minStartDate(date: Date): boolean {
    const currentDay = moment().toDate();
    const isTodayOrLater = moment(date).isSameOrAfter(currentDay, 'day');

    const isChanged = !moment(date).isSame(this.startTime, 'day');
    return !isChanged || isTodayOrLater;
  }

  minDueDate(date: Date): boolean {
    if (this.published) {
      const isChanged = !moment(date).isSame(this.dueTime, 'day');
      return !isChanged || this.isValidDueDate(date);
    }
    return this.isValidDueDate(date);
  }

  minEndDate(): Date {
    const dueTime = this.dueTime;
    const endTime = this.endTime;
    const currentTime = moment().toDate();

    if (this.published && moment(endTime).isBefore(currentTime)) {
      return moment(endTime).toDate();
    }

    if (!dueTime) {
      return moment().add(1, 'day').toDate();
    }

    return moment.max([moment(dueTime).add(1, 'day'), moment()]).toDate();
  }

  isValidDueDate(dueDate: Date): boolean {
    if (this.isExam) {
      return this.isValidExamDueDate(dueDate);
    }

    const startTime = this.startTime;
    const currentTime = moment().toDate();

    if (!startTime || moment(startTime).isBefore(currentTime)) {
      return moment(dueDate).isSameOrAfter(moment().toDate(), 'day');
    }

    return moment(dueDate).isSameOrAfter(moment(startTime).toDate(), 'day');
  }

  get includeQuestions(): string {
    const questionIncludes = {
      all: 'learnosity::Questions',
      exam: 'learnosity::ExamQuestions',
      nonExam: 'learnosity::NonExamQuestions',
    };

    return questionIncludes[this.learnosityQuestionsType];
  }

  get questionFilter(): IQuestionFilter | {} {
    const filters = {
      all: {},
      exam: { 'variants.exam': true },
      nonExam: { 'variants.exam': false },
    };

    return filters[this.learnosityQuestionsType];
  }

  get learnosityQuestionsType(): LearnosityQuestionsType {
    if (!this.course.hasExams) {
      return 'all';
    } else if (this.kind === 'assignment') {
      return 'nonExam';
    } else {
      return 'exam';
    }
  }

  get supportsManualGrading(): boolean {
    return this.course?.hasManualGrading;
  }

  get supportsExcel(): boolean {
    return this.course?.hasExcelGrading;
  }

  defaultStartTime(inEmbedMode = false): Date {
    if (inEmbedMode || this.isFromLti) {
      return moment().toDate();
    } else {
      return moment().add(1, 'hour').startOf('hour').toDate();
    }
  }

  defaultStudentSettingsTime(inEmbedMode = false): Date {
    if (inEmbedMode || this.isFromLti) {
      return moment().startOf('hour').add(1, 'hour').toDate();
    } else {
      return this.dueTime || this.endTime;
    }
  }

  defaultShowGradesTime(inEmbedMode = false): Date {
    if (inEmbedMode || this.isFromLti) {
      return moment().startOf('hour').add(1, 'hour').toDate();
    } else {
      return this.dueTime;
    }
  }

  get manuallyGradedQuestions(): AssignmentQuestion[] {
    return this.assignmentQuestions
            && this.hasManualGrading
            && this.assignmentQuestions.filter(assignmentQuestion => assignmentQuestion.question.manuallyGraded);
  }

  get autoGradedQuestions(): AssignmentQuestion[] {
    return this.assignmentQuestions
            && this.assignmentQuestions.filter(assignmentQuestion => !assignmentQuestion.question.manuallyGraded);
  }

  get forthcomingCopyName(): string {
    const { name } = this;
    const dateSuffix = `(${moment().format('l')})`;

    if (SINGLE_COPY_SUFFIX_REGEX.test(name)) { return `${name} ${dateSuffix}`; }

    if (COPY_WITH_DATE_SUFFIX_REGEX.test(name)) { return name.replace(DATE_SUFFIX_REGEX, dateSuffix); }

    if (MULTIPLE_COPY_SUFFIX_REGEX.test(name)) { return name.replace(MULTIPLE_COPY_SUFFIX_REGEX, 'Copy'); }

    return `${name} Copy`;
  }

  isDirty(persisted: Assignment): boolean {
    if (!this.hasDirtyAttributes) { return false; }
    if (this.datesEnabled || !persisted || persisted.hasDueEndTime) { return true; }

    // Get array of changed attributes (prefixed with underscore)
    const changedAttributes = _.reduce(this, (acc, val, key) => (
      (key[0] === '_' && !this.isEqual(val, persisted[key]))
        ? [...acc, key]
        : acc
    ), []);

    // Do not consider dirty if only nullable, disabled time attributes changed
    return !_.isEqual(changedAttributes, NULLABLE_TIME_ATTRIBUTES);
  }

  dateLabel(prop: keyof Assignment, format?: string): string {
    return new FormatDatePipe().transform(this[prop], format);
  }

  private isEqual(val1: unknown, val2: unknown): boolean {
    return (!!val1 && !!val2)
      ? _.isEqual(val1, val2) // Deep comparison of truthy values or objects
      : !!val1 === !!val2;    // Compare via boolean coercion if either value is falsy
  }

  private get hasDueTime(): boolean {
    return !!(this.dueTime && !isNaN(this.dueTime.valueOf()));
  }

  private get hasEndTime(): boolean {
    return !!(this.endTime && !isNaN(this.endTime.valueOf()));
  }

  private isValidExamDueDate(dueDate: Date): boolean {
    const startTime = this.startTime;
    const currentTime = moment().toDate();
    const from = (!startTime || moment(startTime).isBefore(currentTime, 'day')) ?
      moment() : moment(startTime);

    return moment(dueDate).isSameOrAfter(from.add(this.timeLimit, 'minutes').toDate(), 'day');
  }

  private updateDueTime() {
    const minDueTime = moment(this.startTime).add(1, 'day').toDate();

    if (minDueTime > this.dueTime) {
      this.changeDateOnly(minDueTime, 'dueTime');

      this.updateEndTime();
    }
  }

  private updateEndTime() {
    if (!this.allowLateSubmissions) {
      this.endTime = this.dueTime;
    } else {
      const minEndTime = this.defaultEndTime;

      if (minEndTime > this.endTime) {
        this.changeDateOnly(minEndTime, 'endTime');
      }
    }
  }

  private changeDateOnly(newDate: Date, key: string) {
    if (!newDate) { return this[key] = null; }

    const newYear = newDate.getFullYear();
    const newMonth = newDate.getMonth();
    const newDay = newDate.getDate();

    if (this[key]) {
      this[key] = moment(this[key]).set({year: newYear, month: newMonth, date: newDay}).toDate();
    }
  }
}
