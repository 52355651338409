<div class="server-error">
  <div class="header">
    <h2 mat-dialog-title>Server Error</h2>
    <button mat-icon-button
            mat-dialog-close
            aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="message">
      Sorry, we're experiencing temporary difficulties! Please try again later.
    </div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Details</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <p>User ID: {{ userId || 'N/A' }}</p>
        <p>Error Time: {{ errorTime | formatDate: 'MMMM D, YYYY [at] LTS z' }}</p>
        <p>Status: {{ data.error.status }}</p>
        <p>Request: {{ data.request.url }}</p>
        <p>Body: {{ data.request.body | json }}</p>
        <p>Error: {{ data.error | json }}</p>
      </mat-card-content>
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button
            color="primary"
            cdkFocusInitial
            (click)="reload()">
      Reload
    </button>
  </mat-dialog-actions>
</div>
