<app-top-bar *ngIf="showNavigation$ | async"
             class="top-bar"
             [opened]="isSideNavOpen$ | async"
             (toggleSideNavigation)="toggleSideNav()"
             (closeSideNavigation)="isSideNavOpen$.next(false)"
             (logout)="logoutInitiated()">
</app-top-bar>
<mat-sidenav-container appGoogleTagsScript
                       class="global-navigation-container"
                       [ngClass]="{ 'embedded-mode': !(showNavigation$ | async) }">
  <app-loading-bar *ngIf="!(showNavigation$ | async)">
  </app-loading-bar>
  <mat-sidenav #sidenav
               [opened]="isSideNavOpen$ | async"
               (openedChange)="onOpenedChange($event)"
               class="responsive-sidenav-container">
    <app-side-navigation *ngIf="showNavigation$ | async"
                         (logout)="logoutInitiated()"
                         (closeNav)="isSideNavOpen$.next(false)">
    </app-side-navigation>
  </mat-sidenav>

  <div class="bottom-navigation-container">
    <app-side-navigation *ngIf="showNavigation$ | async"
      class="left-navigation"
      (closeNav)="isSideNavOpen$.next(false)"
      (logout)="logoutInitiated()">
    </app-side-navigation>

    <main id="main" class="content-holder">
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
      <app-desktop-footer class="footer"></app-desktop-footer>
    </main>
  </div>
</mat-sidenav-container>
