import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationService } from 'app/authentication/location.service';
import moment from 'moment';
import { AuthenticationStorage } from '../authentication/auth.service';

export interface IServerError {
  request: HttpRequest<unknown>;
  error: HttpErrorResponse;
}

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
})
export class ServerErrorComponent {
  errorTime = moment();
  userId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IServerError,
    private authStorage: AuthenticationStorage,
    private location: LocationService) {
    this.userId = this.authStorage.userId;
  }

  reload() {
    this.location.reload();
  }
}
