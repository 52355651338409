import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Course } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'course-copies',
})
export class CourseCopy extends Model {
  @Attribute() finished: boolean;

  @BelongsTo() original: Course;
  @BelongsTo() copy: Course;
}
