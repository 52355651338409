import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

export const DEFAULT_MAX_RESULTS = 20;

@Pipe({
  name: 'filterOptions',
})
export class AutocompleteFilterOptionsPipe<T> implements PipeTransform {
  transform(array: T[], input: string, property: string, maxResults = DEFAULT_MAX_RESULTS): T[] {
    if (!input) {
      return _.take(array, maxResults);
    }

    return _.chain(array)
      .filter((item) => {
        const value = item?.[property];
        if (!value) {
          return false;
        }

        return _.includes(value.toLowerCase(), input.toLowerCase());
      })
      .take(maxResults)
      .value();
  }
}
