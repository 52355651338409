import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import type { Assignment } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'assignment-aggregations',
})
export class AssignmentAggregation extends Model {
  @Attribute() meanScore: number;
  @Attribute() medianScore: number;
  @Attribute() modeScore: number;
  @Attribute() maximumScore: number;
  @Attribute() minimumScore: number;
  @Attribute() numberOfAttempts: number;

  @BelongsTo() assignment: Assignment;
}
