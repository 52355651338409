import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Question } from '../';
import { Model } from '../model';

@JsonApiModelConfig({
  type: 'learnosity/question-item-previews',
})
export class QuestionItemPreview extends Model {
  @BelongsTo() question: Question;
  @Attribute() packet: ILearnosityItemsApiInitializationObject;
}
