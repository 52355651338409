import { Attribute, HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import type { Course } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'canonical-institutions',
})
export class Institution extends Model {
  @Attribute() name: string;

  @HasMany() courses: Course[];
}
