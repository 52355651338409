import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'filter',
})
export class FilterPipe<T> implements PipeTransform {
  transform<K extends keyof T>(collection: T[], propertyPath: string, value: K): T[] {
    return _.filter(collection, (elem) => elem?.[propertyPath] === value);
  }
}
