import { NestedTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import _ from 'lodash';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { IJsonApiQueryParams } from './datastore/datastore.service';
import { Chapter } from './model';
import { PaginationService } from './pagination.service';

export class ChapterTreeControl extends NestedTreeControl<ChapterNode> {
  constructor() {
    super((node: ChapterNode) => node.children$);
  }
}

export class ChapterNode {
  children$: Observable<ChapterNode[]>;
  private toggled: ReplaySubject<void> = new ReplaySubject<void>(1);
  private generatedChildNodes$: Observable<ChapterNode[]>;

  constructor(public chapter: Chapter, paginationService: PaginationService) {
    this.children$ = this.toggled.asObservable().pipe(
      take(1),
      switchMap(() => {
        if (!this.generatedChildNodes$) {
          this.generatedChildNodes$ = paginationService.all(Chapter, {
            filter: { parent: this.chapter.id },
            include: 'parent',
            sort: 'ordinal',
          }).pipe(
            map((chapters: Chapter[]) => generateChapterNode(chapters, paginationService)),
            shareReplay(1),
          );
        }

        return this.generatedChildNodes$;
      }),
    );
  }

  toggle() {
    this.toggled.next();
  }
}

export class ChapterDataSource extends MatTreeNestedDataSource<ChapterNode> {
  private generatedParentNodes: ChapterNode[];

  constructor(
    private bookId: string,
    private paginationService: PaginationService,
    private chapterService: ChapterService,
  ) {
    super();
  }

  connect(): Observable<ChapterNode[]> {
    const rootChaptersQuery: IJsonApiQueryParams = {
      filter: {
        book: this.bookId,
        root: true,
      },
      include: 'parent',
      sort: 'ordinal',
    };

    return this.paginationService.all(Chapter, rootChaptersQuery).pipe(
      map((chapters: Chapter[]) => {
        if (!this.generatedParentNodes) {
          this.generatedParentNodes = generateChapterNode(chapters, this.paginationService);
        }

        return this.generatedParentNodes;
      }),
      tap(() => this.chapterService.setLoading(this.bookId, false)),
    );
  }
}

export interface IChapterServiceData {
  bookId: string;
  dataSource: ChapterDataSource;
  loading$: BehaviorSubject<boolean>;
  treeControl: NestedTreeControl<ChapterNode>;
}

@Injectable()
export class ChapterService {
  data: IChapterServiceData[] = [];

  constructor(public paginationService: PaginationService) { }

  init(bookId: string, refreshData = false) {
    if (!refreshData && this.getData(bookId)) { return; }

    if (refreshData) {
      this.data = _.reject(this.data, { bookId });
    }

    this.data.push({
      bookId,
      dataSource: new ChapterDataSource(bookId, this.paginationService, this),
      treeControl: new ChapterTreeControl(),
      loading$: new BehaviorSubject(true),
    });
  }

  hasChild = (_n: number, nodeData: ChapterNode): boolean => !!nodeData.chapter.childrenCount;

  dataSource = (bookId: string): ChapterDataSource => this.getData(bookId).dataSource;

  treeControl = (bookId: string): NestedTreeControl<ChapterNode> => this.getData(bookId).treeControl;

  setLoading = (bookId: string, value: boolean) => this.getData(bookId).loading$.next(value);

  loading$(bookId: string): Observable<boolean> {
    const loadingSubject = this.getData(bookId)?.loading$;

    return loadingSubject
      ? loadingSubject.asObservable()
      : of(false);
  }

  private getData = (bookId: string): IChapterServiceData => _.find(this.data, ['bookId', bookId]);
}

const generateChapterNode = (chapters: Chapter[], paginationService: PaginationService): ChapterNode[] => (
  _.map(chapters, (chapter) => new ChapterNode(chapter, paginationService))
);
