import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Cookies, Urls } from '../constants';
import { NavigationWatch } from '../loading/navigation-watch';
import { User } from '../model';
import { AccessTokenStorageService } from './access-token-storage.service';
import { LoginPageNavigator, UserFetcher } from './return-options';
import { UserIdStorageService } from './user-id-storage.service';

interface IError {
  status: string;
}

@Injectable()
export class AuthenticationStorage {
  constructor(private accessTokenStorageService: AccessTokenStorageService,
              private userIdStorageService: UserIdStorageService,
              private cookieService: CookieService) {}

  public isAuthenticated() {
    return !!this.accessTokenStorageService.token && !!this.cookieService.get(Cookies.REMEMBER_USER_TOKEN);
  }

  public cleanUpLoginInfo() {
    // Should be removed by the server, but do it here as well, just to be sure.
    this.cookieService.delete(Cookies.FWK_SESSION);

    this.cleanPracticeLoginInfo();
  }

  public cleanPracticeLoginInfo() {
    this.userIdStorageService.userId = null;
    this.accessTokenStorageService.token = null;
    this.cookieService.delete(Cookies.APP_SERVER_SESSION);
    this.cookieService.delete(Cookies.REMEMBER_USER_TOKEN);
  }

  public store(token: string, id: number) {
    this.accessTokenStorageService.token = token;
    this.userIdStorageService.userId = id;
  }

  public get token(): string {
    return this.accessTokenStorageService.token;
  }

  public get userId$(): Observable<number> {
    return this.userIdStorageService.userId$;
  }

  public get userId(): number {
    return this.userIdStorageService.userId;
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authStorage: AuthenticationStorage,
              private navigationWatch: NavigationWatch,
              @Inject('navigateToLoginPage') private navigateToLoginPage: LoginPageNavigator) {}
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authStorage.isAuthenticated()) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authStorage.token}`,
        },
      });
    }

    return next.handle(req).pipe(
      catchError(err => {
        if (err.status !== 401) {
          return throwError(() => err);
        }

        this.authStorage.cleanUpLoginInfo();

        if (!this.navigationWatch.currentlyResolving) {
          // otherwise will be handled by the NavigationError handler
          this.navigateToLoginPage();
        }

        return throwError(() => err);
      }),
    );
  }
}

@Injectable()
export class AuthService {
  private _currentUserBuffer: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private _currentUser: Observable<User>;

  constructor(private authStorage: AuthenticationStorage,
              private http: HttpClient,
              private router: Router,
              @Inject('fetchUser') private fetchUser: UserFetcher,
              @Inject('navigateToLoginPage') private navigateToLoginPage: LoginPageNavigator) {
    this.router.events
      .pipe(
        filter(event => event.constructor === NavigationError),
        map(event => <NavigationError>event),
      )
      .subscribe(event => {
        const errors: IError[] = event?.error?.errors ?? [];
        const unauthorized = _.chain(errors)
          .filter((error) => !!error.status)
          .some(error => error.status === '401')
          .value();

        if (unauthorized) {
          this.navigateToLoginPage(event.url);
        }
      });

    this.refresh();

    this._currentUser = this._currentUserBuffer
      .asObservable();
  }

  public authenticate(): Observable<any> {
    if (this.authStorage.isAuthenticated()) {
      return this.currentUser.pipe(
        filter(user => !!user),
        first());
    }

    return this.http.post(Urls.AUTHENTICATE, { client_id: Urls.AUTH_CLIENT_ID }, {}).pipe(
      tap((response) => {
        this.authStorage.store(
          response['access_token'],
          parseInt(response['user_id'], 10));
      }),
      switchMap(() => this.currentUser.pipe(
        filter(user => !!user),
        first(),
      )),
    );
  }

  public refresh(): Observable<User> {
    const response$ = this.authStorage
      .userId$.pipe(
        switchMap((userId: number): Observable<User> => {
          if (!userId) {
            return of(null);
          }
          return this.fetchUser(userId);
        }),
        shareReplay(1),
      );

    response$.subscribe(this._currentUserBuffer);
    return response$;
  }

  public logout(_returnBack: boolean = false): Observable<any> {
    return this.http.post(Urls.LOGOUT, null, {}).pipe(
      tap(() => {
        this.authStorage.cleanUpLoginInfo();
      }));
  }

  public get currentUser(): Observable<User> {
    return this._currentUser;
  }

  public get currentUserLoaded(): Observable<User> {
    return this._currentUser.pipe(
      filter(user => !!user),
      first(),
      shareReplay(1));
  }
}
