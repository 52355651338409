import { HasMany } from '@asteasolutions/angular2-jsonapi';
import { Model } from '../model';
import type { Tag } from './internal';

export class Taggable extends Model {
  @HasMany({
    key: 'learnosity::Tags',
  })
  'learnosity::Tags': Tag[];

  public get learnosityTags(): Tag[] {
    return this['learnosity::Tags'];
  }
}
