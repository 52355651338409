import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Course } from './internal';
import { Model } from './model';

export interface IChapterMapping {
  [oldBookChapterId: string]: string;
}

export interface IChapterMapData {
  droppedChapters?: string[];
  mapping?: IChapterMapping;
}

export interface ICourseBookUpgradeEntry {
  chapterId: string;
  chapterDeepOrdinal: string;
  chapterName: string;
  chapterBookName: string;
  chapterBookId: string;
  matchedChapterId: string;
  matchedChapterDeepOrdinal: string;
  matchedChapterName: string;
  textbookAssignmentQuestions: number;
  customAssignmentQuestions: number;
  unassignedCustomQuestions: number;
}

@JsonApiModelConfig({
  type: 'course-book-upgrades',
})
export class CourseBookUpgrade extends Model {
  @Attribute() packet: ICourseBookUpgradeEntry[];
  @Attribute() chapterMapData?: IChapterMapData;

  @BelongsTo() course: Course;
}
