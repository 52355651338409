import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import type { Assignment, Question } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'assignment-questions',
})
export class AssignmentQuestion extends Model {
  @Attribute() sortPosition: number;
  @Attribute() count: number;

  @BelongsTo() assignment: Assignment;
  @BelongsTo() question: Question;

  get isUnhealthy(): boolean {
    return this._isUnhealthy;
  }

  set isUnhealthy(val: boolean) {
    this._isUnhealthy = val;
  }

  validateCount() {
    this.count = Math.max(this.count, 1);
    this.count = Math.min(this.count, this.question.variantsCount);
  }
}
