import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Question } from '../internal';
import { Model } from '../model';

@JsonApiModelConfig({
  type: 'learnosity/question-authorings',
})
export class QuestionAuthoring extends Model {
  @BelongsTo() question: Question;
  @Attribute() packet: ILearnosityAuthoringApiInitializationObject;
}
