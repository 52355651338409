import { ErrorHandler, Injectable } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import _ from 'lodash';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { EnvService } from 'app/env.service';
import { environment } from 'environments/environment';

interface IAirbrakeConfig {
  id: number;
  key: string;
}

@Injectable()
export class AirbrakeCredentialsService {
  constructor(private envService: EnvService) { }

  getConfig(): IAirbrakeConfig {
    return this.envService.isProduction
      ? environment.airbrakeCredentials
      : environment.airbrakeCredentialsStaging;
  }
}

@Injectable()
export class AirbrakeErrorHandler implements ErrorHandler {
  errors: ReplaySubject<Error> = new ReplaySubject();
  errors$: Observable<Error> = this.errors.asObservable();

  constructor(private credentialsService: AirbrakeCredentialsService) {
    of(this.credentialsService.getConfig()).pipe(
      filter(Boolean),
      filter((config: IAirbrakeConfig) => !!config.id && !!config.key),
      map((config: IAirbrakeConfig) => new Notifier({ projectId: config.id, projectKey: config.key })),
      take(1),
      tap((airbrake) => this.filterErrors(airbrake)),
      switchMap((airbrake) => this.errors$.pipe(
        tap(error => airbrake.notify(error)),
      )),
    ).subscribe();
  }

  handleError(error: Error) {
    this.errors.next(error);
    throw error;
  }

  filterErrors(airbrake: Notifier) {
    airbrake.addFilter((notice) => {
      const shouldIgnoreError = _.chain(notice.errors)
        .map('message')
        .first()
        .includes('Extension context invalidated.')
        .value();
      if (shouldIgnoreError) {
        return null;
      }

      return notice;
    });
  }
}
