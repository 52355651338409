import { Attribute, BelongsTo, HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import type {
  Assessment,
  Assignment,
  AttemptResponse,
  IDetailsReportable,
  Revision,
  StudentAssignment,
  StudentDetailsAssignmentReport,
  User,
} from './internal';
import { Model } from './model';

export type AttemptStatus = 'initialized' | 'started' | 'submitted';

@JsonApiModelConfig({
  type: 'attempts',
})
export class Attempt extends Model implements IDetailsReportable {
  @Attribute() sessionId: string;
  @Attribute() status: AttemptStatus;
  @Attribute() expirationTime: Date;
  @Attribute() secondsUntilExpiration: number;
  @Attribute() score: number;
  @Attribute() maxScore: number;
  @Attribute() penalizedScorePoints: number;
  @Attribute() scorePercent: number;
  @Attribute() scorePenaltyPercent: number;
  @Attribute() repeatPenaltyPercent: number;
  @Attribute() latePenaltyPercent: number;
  @Attribute() penalizedScorePercent: number;
  @Attribute() submittedAt: Date;
  @Attribute() accessCodeToken: string;
  @Attribute() accessGranted: boolean;
  @Attribute() needsManualGrading: boolean;
  @BelongsTo() assignment: Assignment;
  @BelongsTo() user: User;
  @BelongsTo() revision: Revision;

  @HasMany() attemptResponses: AttemptResponse[];
  @HasMany() assessments: Assessment[];

  @BelongsTo({
    key: 'learnosity::StudentAssignment',
  })
  'learnosity::StudentAssignment': StudentAssignment;

  get studentAssignment(): StudentAssignment {
    return this['learnosity::StudentAssignment'];
  }

  set studentAssignment(assignment: StudentAssignment) {
    this['learnosity::StudentAssignment'] = assignment;
  }

  @BelongsTo({
    key: 'learnosity::StudentDetailsAssignmentReport',
  })
  'learnosity::StudentDetailsAssignmentReport': StudentDetailsAssignmentReport;

  get studentDetailsReport(): StudentDetailsAssignmentReport {
    return this['learnosity::StudentDetailsAssignmentReport'];
  }

  get revisionScore() {
    if (!this.isEdited) { return null; }

    return this.revision.score;
  }

  get finalScore() {
    if (!this.isEdited) {
      return this.score;
    }

    return this.revision.score;
  }

  get finalScorePercent(): number {
    if (_.isNil(this.finalScore)) {
      return null;
    }

    if (this.maxScore === 0) {
      return 0;
    }

    return _.round((this.finalScore * 100) / this.maxScore, 2);
  }

  get finalPenalizedScore(): number {
    return this.penalizedScore(this.finalScore);
  }

  get finalPenalizedScorePercent(): number {
    return this.penalizedScore(this.finalScorePercent);
  }

  get isEdited(): boolean {
    return !!this.revision;
  }

  get editReason(): string {
    return this.isEdited ? this.revision.reason : null;
  }

  get gradeAvailable(): boolean {
    return !_.isNil(this.penalizedScorePercent);
  }

  get gradeUnavailableLabel(): string {
    if (this.assignment?.showGradesNow) { return 'Calculating...'; }

    return this.assignment?.showGradesInFuture ? 'Not yet available' : 'Hidden';
  }

  get timeExpired(): boolean {
    return !_.isNil(this.secondsUntilExpiration) && this.secondsUntilExpiration <= 0;
  }

  private penalizedScore(score: number): number {
    return _.round((score * (100 - this.scorePenaltyPercent)) / 100, 2);
  }
}
