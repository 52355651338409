import { Attribute, BelongsTo, HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Resource, User } from './internal';
import { Model } from './model';

export type RoleName = 'admin' | 'editor' | 'educator' | 'collaborator';

@JsonApiModelConfig({
  type: 'roles',
})
export class Role extends Model {
  @Attribute() name: RoleName;

  @HasMany() users: User[];
  @BelongsTo() resource?: Resource;
}
