import { Injectable } from '@angular/core';
import { EnvService } from 'app/env.service';
import { User } from 'app/model';
import { environment } from 'environments/environment';
import _ from 'lodash';
import LogRocket from 'logrocket';
import { Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

export interface ILogRocketConfig { id: string }

@Injectable()
export class LogRocketService {
  private config$ = this.getConfig().pipe(shareReplay(1));

  constructor(private envService: EnvService) { }

  init(): Observable<ILogRocketConfig> {
    return this.config$.pipe(
      tap((config: ILogRocketConfig) => {
        if (!this.shouldRun(config)) { return; }

        LogRocket.init(config.id);
      }),
    );
  }

  identify(user: User): Observable<ILogRocketConfig> {
    return this.config$.pipe(
      tap((config: ILogRocketConfig) => {
        if (!this.shouldRun(config)) { return; }

        LogRocket.identify(`${user.flatworldId}`, {
          name: user.displayName,
          email: user.email,
        });
      }),
    );
  }

  private getConfig(): Observable<ILogRocketConfig> {
    return of(environment.logRocketCredentials);
  }

  private shouldRun(config: ILogRocketConfig): boolean {
    return config?.id && this.envService.isProduction;
  }
}
