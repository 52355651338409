<h2 mat-dialog-title *ngIf="title.length">{{ title }}</h2>

<mat-dialog-content class="dialog-question" [innerHTML]="question | sanitize"></mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary">
    {{ cancel || 'Cancel' }}
  </button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary">
    {{ confirmation || 'Confirm' }}
  </button>
</mat-dialog-actions>
