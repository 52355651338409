import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Question, RecentUsage } from '../internal';
import { Model } from '../model';
import type { QuestionAuthoring, QuestionItemPreview, QuestionPreview, QuestionScore } from './internal';

@JsonApiModelConfig({
  type: 'learnosity/questions',
})
export class LearnosityQuestion extends Model {
  @BelongsTo() question: Question;
  @Attribute() kind: string;
  @Attribute() stimulus: string;
  @Attribute() solution: string;
  @Attribute() learnosityReference: string;
  @Attribute() questionReference: string;
  @Attribute() deletedInLearnosity: boolean;
  @Attribute() targetQuestionId: string;
  @Attribute() totalPoints: number;
  @Attribute() rubric: string;
  @Attribute() revisedAt: Date;

  @BelongsTo({
    key: 'learnosity::QuestionPreview',
  })
  'learnosity::QuestionPreview': QuestionPreview;

  public get questionPreview(): QuestionPreview {
    return this['learnosity::QuestionPreview'];
  }

  @BelongsTo({
    key: 'learnosity::QuestionItemPreview',
  })
  'learnosity::QuestionItemPreview': QuestionItemPreview;

  public get questionItemPreview(): QuestionItemPreview {
    return this['learnosity::QuestionItemPreview'];
  }

  @BelongsTo({
    key: 'learnosity::QuestionAuthoring',
  })
  'learnosity::QuestionAuthoring': QuestionAuthoring;

  public get questionAuthoring(): QuestionAuthoring {
    return this['learnosity::QuestionAuthoring'];
  }

  @BelongsTo({
    key: 'learnosity::QuestionScore',
  })
  'learnosity::QuestionScore': QuestionScore;

  @BelongsTo() recentUsage: RecentUsage;

  public get questionScore(): number {
    return this['learnosity::QuestionScore'] && Number(this['learnosity::QuestionScore'].packet);
  }

  public get hasRubric() {
    return this.rubric?.length;
  }

  public get recentlyUsed(): boolean {
    return this.recentUsage?.packet;
  }
}
