import { HttpHeaders } from '@angular/common/http';
import { ErrorResponse, JsonApiError, JsonApiModel } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export abstract class Model extends JsonApiModel {
  static dirtyObjects = {};

  public get isPersisted(): boolean {
    return !!this.id;
  }

  public get dirty(): boolean {
    return Model.dirtyObjects?.[this.constructor.name]?.[this.id];
  }

  public set dirty(value: boolean) {
    _.setWith(Model.dirtyObjects, [this.constructor.name, this.id], value, Object);
  }

  public hasDirtyAttribute(attributeName: string): boolean {
    const attributesMetadata = Reflect.getMetadata('Attribute', this);
    if (!attributesMetadata.hasOwnProperty(attributeName)) {
      return false;
    }
    const metadata = attributesMetadata[attributeName];
    return metadata.hasDirtyAttributes;
  }

  public validate(): Observable<boolean> {
    return of(this.ensureValidity()).pipe(
      map((errors: JsonApiError[]) => {
        if (errors.length !== 0) {
          throw new ErrorResponse(errors);
        }
        return true;
      }));
  }

  public save(params?: any, headers?: HttpHeaders): Observable<this> {
    return this.validate().pipe(switchMap(() => super.save(params, headers)));
  }

  private ensureValidity(): JsonApiError[] {
    const validations: IValidationMetadata[] = Reflect.getMetadata('Validation', this);

    return _.chain(validations)
      .reject((validation: IValidationMetadata) => !!this[validation.validatorField.toString()].apply(this))
      .map((validation: IValidationMetadata): JsonApiError[] => (
        _.map(validation.keys, (key) => <JsonApiError>{
          title: validation.message,
          detail: `${key} - ${validation.message}`,
          source: {
            pointer: key,
          },
        })
      ))
      .flatten()
      .value();
  }
}

interface IValidationMetadata {
  validatorField: string | symbol;
  message: string;
  keys: string[];
}

const addValidation = (target, validation: IValidationMetadata) => {
  const validations: IValidationMetadata[] = Reflect.getMetadata('Validation', target) || [];

  validations.push(validation);

  Reflect.defineMetadata('Validation', validations, target);
};

export const Validation = (fieldKeys: string | Array<string> = [], message: string) => {
  const keys = (!_.isArray(fieldKeys)) ? [fieldKeys] : fieldKeys;

  return (target: any, validatorField: string | symbol) => {
    addValidation(target, {
      validatorField,
      keys,
      message,
    });
  };
};

export const Validate = {
  Presence: () => (target: any, field: string | symbol) => {
    const validatorField = `${field.toString()}Present`;
    const keys = [field.toString()];
    const message = 'must be present';

    Object.defineProperty(target, validatorField, {
      get() {
        return () => this[field];
      },
      enumerable: true,
      configurable: false,
    });

    addValidation(target, { validatorField, keys, message });
  },
};
