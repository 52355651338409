import { Directive, HostBinding, HostListener, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, takeUntil } from 'rxjs/operators';

type Hoverable = any;

@Injectable()
export class HoverService {
  private target: BehaviorSubject<Hoverable> = new BehaviorSubject<Hoverable>(null);

  public watchChange$(hoverTarget: Hoverable): Observable<boolean> {
    return this.target
      .pipe(
        map((currentHoverTarget: Hoverable) => currentHoverTarget === hoverTarget),
        distinctUntilChanged(),
        shareReplay(1),
      );
  }

  public hoverMe(target: Hoverable) {
    if (target === this.target.value) {
      return;
    }
    this.target.next(target);
  }
  public unhoverMe(target: Hoverable) {
    if (target !== this.target.value) {
      return;
    }
    this.target.next(null);
  }
}

@Directive({
  selector: '[appHoverTarget]',
})
export class HoverableDirective implements OnInit, OnDestroy {
  @Input() appHoverTarget: Hoverable;
  @Input() hoverClass = '';

  private unsubscribe$: Subject<void> = new Subject();
  private _elementClass: string[] = [];

  constructor(private hoverService: HoverService) { }

  @Input('class')
  @HostBinding('class')
  get elementClass(): string {
    return this._elementClass.join(' ');
  }
  set elementClass(values: string) {
    this._elementClass = values.split(' ');
  }

  @HostListener('mouseover')
  setHovered() {
    this.hoverService.hoverMe(this.appHoverTarget);
  }
  @HostListener('mouseout')
  setUnhovered() {
    this.hoverService.unhoverMe(this.appHoverTarget);
  }

  ngOnInit() {
    this.hoverService.watchChange$(this.appHoverTarget)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(hovered => {
        if (hovered) {
          this._elementClass = _.concat(this._elementClass, [this.hoverClass]);
        } else {
          this._elementClass = _.without(this._elementClass, this.hoverClass);
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
