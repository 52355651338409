import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserFirstNameComponent } from './user-first-name/user-first-name.component';

@NgModule({
  declarations: [
    UserFirstNameComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    UserFirstNameComponent,
  ],
})
export class UserFirstNameModule { }
