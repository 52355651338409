import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NEVER, Observable, OperatorFunction, catchError } from 'rxjs';
import { ErrorResponse } from '@asteasolutions/angular2-jsonapi';
import { SnackbarComponent } from '../custom-components/snackbar/snackbar.component';

export type NotificationType = 'warning' | 'confirmation';

interface IAction {
  name: string;
  callback?: () => unknown;
}

export interface INotificationConfig {
  duration?: number;
  type?: NotificationType;
  action?: IAction;
}

export interface ISnackbarData {
  message: string;
  actionName: string;
  type: NotificationType;
}

const DEFAULT_DURATION = 5000;

export abstract class Notifier {
  abstract onSuccess(message: string, config?: INotificationConfig): void;
  abstract onError(message: string, error: Error | ILearnosityError, context?: any, config?: INotificationConfig): void;
  abstract close(): void;
}

@Injectable()
export class ToastNotifier implements Notifier {
  private snackbarRef: MatSnackBarRef<SnackbarComponent>;

  constructor(protected snackbar: MatSnackBar) { }

  onSuccess(message: string, config: INotificationConfig = {}) {
    if (!config.type) {
      config.type = 'confirmation';
    }

    this.show(message, config);
  }

  onError(message: string, _error: Error | ILearnosityError, _context?: any, config: INotificationConfig = {}) {
    if (!config.type) {
      config.type = 'warning';
    }

    this.show(message, config);
  }

  close() {
    if (this.snackbarRef) { this.snackbarRef.dismiss(); }
  }

  private show(message: string, config: INotificationConfig) {
    const duration = config?.duration ?? DEFAULT_DURATION;
    const actionName = config?.action?.name ?? '';
    const { type } = config;
    const data: ISnackbarData = {
      message,
      actionName,
      type,
    };

    this.snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, {
      data,
      duration,
      panelClass: type,
    });

    if (config?.action?.callback) {
      this.snackbarRef.onAction().subscribe(() => {
        config.action.callback();
      });
    }
  }
}

export const handleError = <T>(notifier: Notifier, action: string): OperatorFunction<T, T> => (source: Observable<T>): Observable<T> => (
  source.pipe(catchError((error: ErrorResponse) => {
    const message = error?.errors?.[0]?.title ?? 'An error has occurred';
    notifier.onError(`Could not ${action}: ${message}`, null);
    return NEVER;
  }))
);
