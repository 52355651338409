import { Directive, ElementRef, Input } from '@angular/core';
import _ from 'lodash';
import { Observable, OperatorFunction } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { MutationObserverDirective } from './mutation-observer.directive';

@Directive({
  selector: '[appHideLearnosityQuestionTypes]',
})
export class HideLearnosityQuestionTypesDirective extends MutationObserverDirective {
  @Input() public appHideLearnosityQuestionTypes: string[];

  constructor(element: ElementRef) {
    super(element);
  }

  protected reactToMutations: OperatorFunction<void, void> = (input: Observable<void>): Observable<void> => (
    input.pipe(
      throttleTime(300),
      tap(() => this.hideQuestionTypes()),
    )
  );

  private hideQuestionTypes() {
    _.each(this.appHideLearnosityQuestionTypes, (id) => {
      const authorApiElement = this.element.nativeElement;
      const tileFinder = `a[role=button][data-lrn-qe-template-reference="${id}"]`;
      const tileElement = authorApiElement.querySelector(tileFinder);
      if (!tileElement) { return; }

      tileElement.parentElement.style.display = 'none';
    });
  }
}
