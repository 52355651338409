import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { OperatorFunction, Subject } from 'rxjs';

@Directive()
export abstract class MutationObserverDirective implements OnInit, OnDestroy {
  protected abstract reactToMutations: OperatorFunction<void, void>;
  private observer: MutationObserver;

  constructor(protected element: ElementRef) { }

  ngOnInit() {
    const subject = new Subject<void>();
    this.observer = new MutationObserver(_.bind(subject.next, subject));
    this.observer.observe(this.element.nativeElement, {
      childList: true, subtree: true,
    });
    subject.asObservable().pipe(this.reactToMutations)
      .subscribe();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }
}
