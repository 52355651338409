<div class="mat-body-2">
  <h1>Not found</h1>
  <p class="mat-body-2">
    Unfortunately, you've visited an <b>outdated or invalid link</b>
    or you are <b>not authorized to access this page.</b>
  </p>
  <button mat-raised-button
          color="primary"
          routerLink="/">
    Go to Home
  </button>
  <button mat-raised-button
          color="primary"
          [routerLink]="path$ | async"
          [queryParams]="params$ | async"
          [replaceUrl]="true">
    Try Again 
  </button>
</div>
