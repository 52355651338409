import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hintStatus',
})
export class HintStatusPipe implements PipeTransform {

  transform(hintAvailable: boolean): string {
    return hintAvailable ? 'YES' : 'NO';
  }
}
