import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Attempt } from '../internal';
import { Model } from '../model';

@JsonApiModelConfig({
  type: 'learnosity/student-assignments',
})
export class StudentAssignment extends Model {
  @BelongsTo() attempt: Attempt;
  @Attribute() packet: ILearnosityItemsApiInitializationObject;
}
