import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appOnAction]',
})
export class OnActionDirective implements OnInit, OnDestroy {
  @Output() appOnAction: EventEmitter<any> = new EventEmitter();
  @Input() disabled = false;
  @Input() throttleTime = 0;

  private subject = new Subject<any>();
  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.subject.pipe(
      throttleTime(this.throttleTime),
      tap((event$) => this.appOnAction.emit(event$)),
    ).subscribe();
  }

  ngOnDestroy() {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

  @HostListener('keydown.enter', ['$event'])
  @HostListener('keydown.space', ['$event'])
  onKeydownEnterOrSpace($event) {
    this.stopDefaultBehavior($event);
  }

  @HostListener('keyup.enter', ['$event'])
  @HostListener('keyup.space', ['$event'])
  @HostListener('click', ['$event'])
  onClickEnterSpace($event) {
    this.stopDefaultBehavior($event);

    if (this.disabled) {
      return;
    }

    this.subject.next($event);
  }

  private stopDefaultBehavior($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
