import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { AttemptResponse } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'assessments',
})
export class Assessment extends Model {
  @Attribute() feedback: string;
  @Attribute() score: number;
  @Attribute() responseId: number;

  @BelongsTo() attemptResponse: AttemptResponse;
}
