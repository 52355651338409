import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import { PluralizePipe } from 'app/pipes/pluralize.pipe';
import _ from 'lodash';
import type { Assignment, Course, User } from './internal';
import { Model } from './model';

export type ExemptionType = 'attempts limit' | 'late penalty';

const pluralizePipe = new PluralizePipe();

@JsonApiModelConfig({
  type: 'exemptions',
})
export class Exemption extends Model {
  @Attribute() attemptsLimit?: number;
  @Attribute() latePenalty: boolean;

  @BelongsTo() student: User;
  @BelongsTo() course: Course;
  @BelongsTo() assignment?: Assignment;

  get hasAttemptsLimit() {
    return _.isNumber(this.attemptsLimit);
  }

  get attemptsLimitDescription() {
    if (!this.hasAttemptsLimit) { return 'No attempts-limit exception'; }

    const limit = this.attemptsLimit === 0 ? 'Unlimited' : this.attemptsLimit;
    const description = pluralizePipe.transform(limit, 'additional attempt', true);
    return `${limit} ${description}`;
  }

  get label(): string {
    const types: string[] = [];
    if (this.hasAttemptsLimit) { types.push('Attempts-Limit'); }
    if (this.latePenalty) { types.push('Late-Penalty'); }

    const description = types.length ? types.join(', ') : 'Empty';
    return `${description} Exception`;
  }
}
