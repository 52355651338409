import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../authentication/auth.service';
import { User } from '../../model';
import { BaseUserDirective } from './base-user.directive';

@Directive({
  selector: '[appIsAdminPanelPermitted]',
})
export class IsAdminPanelPermittedDirective extends BaseUserDirective {
  constructor(templateRef: TemplateRef<any>,
              viewContainer: ViewContainerRef,
              authService: AuthService) {
    super(templateRef, viewContainer, authService);
  }
  protected canBeViewedBy(user: User): boolean {
    return user.adminPanelPermitted;
  }
}
