<div class="side-navigation-container">
  <div class="top">
    <button class="logo-button"
       aria-label="Home page"
       (click)="buttonClicked()"
       [routerLink]="'/'">
    </button>
    <span class="top-message mat-body-2">
      <app-user-first-name></app-user-first-name>
    </span>
  </div>

  <nav class="button-container">
    <ul>
      <li *ngIf="(courses$ | async); let courses" 
          class="dropdown"
          (mouseover)="showCoursesMenu(true)"
          (mouseleave)="showCoursesMenu(false)"
          [ngClass]="{'open': coursesMenuOpen}">
        <a *appIsInEducationMode
                mat-button
                (click)="buttonClicked()"
                class="nav-button"
                [routerLink]="'courses'"
                routerLinkActive #coursesActive="routerLinkActive"
                [class.selected]="coursesActive.isActive"
                [attr.aria-expanded]="coursesMenuOpen"
                aria-haspopup="true">
          <span>Courses</span>
          <mat-icon iconPositionEnd>chevron_right</mat-icon>
        </a>
        <ul class="dropdown-content">
          <li *ngFor="let course of courses">
            <a mat-button
               class="nav-button"
               [routerLink]="['courses', course.id, 'assignments']">
              <span [matTooltip]="course.name"
                    matTooltipShowDelay="1400">
                {{ course.name }}
              </span>
            </a>
          </li>
        </ul>
      </li>
      <li *appHasEnrolledCourses>
        <a
          mat-button
          (click)="buttonClicked()"
          class="nav-button"
          [routerLink]="'assignments'"
          routerLinkActive #assignmentsActive="routerLinkActive"
          [class.selected]="assignmentsActive.isActive">
          All Assignments
        </a>
      </li>
      <li class="editorial-buttons">
        <a *appIsEditorialPermitted
                mat-button
                (click)="buttonClicked()"
                class="nav-button"
                [routerLink]="['/', 'editorial', 'books']"
                routerLinkActive #booksActive="routerLinkActive"
                [class.selected]="booksActive.isActive">
          Books
        </a>
      </li>
      <li class="editorial-buttons">
        <a *appIsRoleManagementPermitted
                mat-button
                (click)="buttonClicked()"
                class="nav-button"
                [routerLink]="['/', 'editorial', 'collaborators']"
                routerLinkActive #rolesActive="routerLinkActive"
                [class.selected]="rolesActive.isActive">
          Collaborators
        </a>
      </li>
      <li>
        <a mat-button
                (click)="dashboardButtonClick()"
                class="nav-button">
          My Bookshelf
        </a>
      </li>
      <li>
        <a *appIsAdminPanelPermitted
              mat-button
              (click)="openAdminPanel()"
              class="nav-button">
          Admin
        </a>
      </li>
      <li class="divider"></li>
      <li>
        <button mat-button
                class="nav-button"
                (click)="logoutButtonClick()">
          Log out
        </button>
      </li>
    </ul>
  </nav>
</div>
