import { Injectable } from '@angular/core';

@Injectable()
export class ScrollService {
  scrollToElement(element: HTMLElement, highlight?: boolean) {
    if (!element) { return; }

    element.scrollIntoView({ behavior: 'smooth' });

    if (highlight) { this.highlightElement(element); }
  }

  scrollToTop() {
    // Per ApplicationNavigationComponent, .content-holder is the main content container
    const container = document.querySelector('.content-holder');
    if (container) { container.scrollTop = 0; }
  }

  private highlightElement(element: HTMLElement) {
    const { animation } = element.style;

    element.style.animation = 'highlight 4s 1s';

    setTimeout(() => {
      if (!element) { return; }

      element.style.animation = animation;
    }, 5000);
  }
}
