import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Assessment, Attempt, LearnosityQuestion } from './internal';
import { Model } from './model';
import type { GradingType } from './question';

@JsonApiModelConfig({
  type: 'attempt-responses',
})
export class AttemptResponse extends Model {
  @Attribute() gradingType: GradingType;

  @BelongsTo() attempt: Attempt;
  @BelongsTo() learnosityQuestion: LearnosityQuestion;
  @BelongsTo() assessment: Assessment;
}
