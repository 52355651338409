import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Attempt } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'revisions',
})
export class Revision extends Model {
  @Attribute() score: number;
  @Attribute() reason: string;
  @BelongsTo() attempt: Attempt;
}
