import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssignmentStatusPipe } from './assignment-status.pipe';
import { AssignmentStudentStatusPipe } from './assignment-student-status.pipe';
import { AutocompleteFilterOptionsPipe } from './autocomplete-filter-options.pipe';
import { AutocompleteRemoveSelectedPipe } from './autocomplete-remove-selected.pipe';
import { CleanupHtmlPipe } from './cleanup-html.pipe';
import { FilterLtiEntitiesPipe } from './filter-lti-entities.pipe';
import { FilterPipe } from './filter.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { HintStatusPipe } from './hint-status.pipe';
import { KeysPipe } from './keys.pipe';
import { LtiTypeFormatPipe } from './lti-type-format.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [
    AssignmentStatusPipe,
    AssignmentStudentStatusPipe,
    AutocompleteFilterOptionsPipe,
    AutocompleteRemoveSelectedPipe,
    FilterLtiEntitiesPipe,
    FilterPipe,
    KeysPipe,
    CleanupHtmlPipe,
    TruncatePipe,
    SanitizePipe,
    LtiTypeFormatPipe,
    HintStatusPipe,
    FormatDatePipe,
    PluralizePipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AssignmentStatusPipe,
    AssignmentStudentStatusPipe,
    AutocompleteFilterOptionsPipe,
    AutocompleteRemoveSelectedPipe,
    FilterLtiEntitiesPipe,
    FilterPipe,
    KeysPipe,
    CleanupHtmlPipe,
    TruncatePipe,
    AutocompleteFilterOptionsPipe,
    AutocompleteRemoveSelectedPipe,
    SanitizePipe,
    LtiTypeFormatPipe,
    HintStatusPipe,
    FormatDatePipe,
    PluralizePipe,
  ],
})
export class PipesModule { }
