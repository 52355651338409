import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Assignment } from 'app/model';

@Component({
  selector: 'app-available-hints-dialog',
  templateUrl: './available-hints-dialog.component.html',
  styleUrls: ['./available-hints-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableHintsDialogComponent {
  public assignment: Assignment;

  constructor(
    public dialogRef: MatDialogRef<AvailableHintsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { assignment: Assignment },
  ) {
    this.assignment = this.data.assignment;
  }

  get showVaryQuestionsPerAttempt(): boolean {
    return this.assignment?.hasVariations && this.assignment?.currentAttemptsLimit !== 1;
  }
}
