import { AfterViewChecked, Directive, ElementRef, Inject, InjectionToken, Input, OnChanges } from '@angular/core';
import { mathjax } from 'mathjax-full/js/mathjax';
import { MathML } from 'mathjax-full/js/input/mathml';
import { TeX } from 'mathjax-full/js/input/tex';
import { SVG } from 'mathjax-full/js/output/svg.js';
import 'mathjax-full/js/input/tex/ams/AmsConfiguration';
import 'mathjax-full/js/input/tex/base/BaseConfiguration';
import 'mathjax-full/js/input/tex/boldsymbol/BoldsymbolConfiguration';
import 'mathjax-full/js/input/tex/braket/BraketConfiguration';
import 'mathjax-full/js/input/tex/cancel/CancelConfiguration';
import 'mathjax-full/js/input/tex/enclose/EncloseConfiguration';
import 'mathjax-full/js/input/tex/mhchem/MhchemConfiguration';
import 'mathjax-full/js/input/tex/newcommand/NewcommandConfiguration';
import 'mathjax-full/js/input/tex/noundefined/NoUndefinedConfiguration';
import 'mathjax-full/js/input/tex/physics/PhysicsConfiguration';
import 'mathjax-full/js/input/tex/verb/VerbConfiguration';
import 'mathjax-full/js/util/Entities';
import 'mathjax-full/js/util/entities/all';

export const REGISTER_DOCUMENT_HANDLER = new InjectionToken('REGISTER_DOCUMENT_HANDLER');

@Directive({
  selector: '[appMathJax]',
})
export class MathJaxDirective implements AfterViewChecked, OnChanges {
  @Input() trigger: any;
  private compiled = false;
  constructor(private el: ElementRef,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              @Inject(REGISTER_DOCUMENT_HANDLER) private _register) {}

  ngOnChanges() {
    this.compiled = false;
  }

  ngAfterViewChecked() {
    if (this.compiled) {
      return;
    }

    this.compile();
    this.compiled = true;
  }

  private compile() {
    const elements = [this.el.nativeElement];

    const html = mathjax.document(this.el.nativeElement.ownerDocument, {
      InputJax: [
        new MathML(),
        new TeX({
          inlineMath: [['\\(', '\\)']],
          packages: [
            'ams',
            'noundefined',
            'newcommand',
            'boldsymbol',
            'braket',
            'mhchem',
            'physics',
            'verb',
            'cancel',
            'enclose',
            'base',
          ],
        }),
      ],
      OutputJax: new SVG({}),
    });
    html.findMath({ elements })
      .compile()
      .getMetrics()
      .typeset()
      .updateDocument()
      .clear();
  }
}
