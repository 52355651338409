import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { browserAdaptor } from 'mathjax-full/js/adaptors/browserAdaptor';
import { RegisterHTMLHandler } from 'mathjax-full/js/handlers/html';
import { MathJaxDirective, REGISTER_DOCUMENT_HANDLER } from './mathjax.directive';

export const registerHTMLHandler =  () => {
  RegisterHTMLHandler(browserAdaptor());
};

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    MathJaxDirective,
  ],
  exports: [
    MathJaxDirective,
  ],
})
export class MathJaxModule {
  static forRoot(): ModuleWithProviders<MathJaxModule> {
    return {
      ngModule: MathJaxModule,
      providers: [
        {
          useFactory: registerHTMLHandler,
          provide: REGISTER_DOCUMENT_HANDLER,
        },
      ],
    };
  }
}
