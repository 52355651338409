import { Injectable } from '@angular/core';
import {
  combineLatest,
  Observable,
  of,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { Course, User } from '../model';
import { AuthService } from './auth.service';

export type UserRole = 'student' | 'educator' | null;

export interface ICourseUserRole {
  canModifyCourses: Observable<boolean>;
  canParticipateInCourses: Observable<boolean>;
}

export class CourseUserRole implements ICourseUserRole {
  private userRole: Observable<UserRole>;
  private _isEducator: Observable<boolean>;
  private _isStudent: Observable<boolean>;

  constructor(course$: Observable<Course>, user$: Observable<User>) {
    this.userRole = combineLatest([course$, user$]).pipe(
      map(([course, user]): UserRole => {
        if (!user) {
          return null;
        }

        if (user.isEducator(course)) {
          return 'educator';
        } else if (user.isStudent(course)) {
          return 'student';
        }
        return null;
      }));

    this._isEducator = this.userRole.pipe(
      map((role: UserRole) => role === 'educator'));

    this._isStudent = this.userRole.pipe(
      map((role: UserRole) => role === 'student'));
  }

  public get canModifyCourses(): Observable<boolean> {
    return this._isEducator;
  }

  public get canParticipateInCourses(): Observable<boolean> {
    return this._isStudent;
  }
}

export class AlwaysFalseUserRole implements ICourseUserRole {
  canModifyCourses: Observable<boolean> = of(false);
  canParticipateInCourses: Observable<boolean> = of(false);
}

@Injectable()
export class CourseUserRoleProvider {
  constructor(private authService: AuthService) {}

  public create(course: Course): ICourseUserRole {
    if (!course) {
      return new AlwaysFalseUserRole();
    }

    return new CourseUserRole(of(course), this.authService.currentUser);
  }
}
