import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Course } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'invitations',
})
export class Invitation extends Model {
  @BelongsTo() course: Course;
  @Attribute() token: string;
  @Attribute() bookReaderUrl: string;
  @Attribute() bookCoverUrl: string;
  @Attribute() courseName: string;
  @Attribute() courseDescription: string;
  @Attribute() courseStartDate: Date;
  @Attribute() courseEndDate: Date;
  @Attribute() courseIdentifier: string;
  @Attribute() sectionIdentifier: string;
  @Attribute() courseInstitution: string;

  @Attribute() studentId: string; // Hack to allow enrollment by invitation
  @Attribute() educatorId: string; // same
}
