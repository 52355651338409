import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class AccessTokenStorageService {
  public static STORAGE_KEY = 'practice_oauth_token';

  constructor(private localStorage: LocalStorageService) { }

  get token(): string {
    return this.localStorage.get(AccessTokenStorageService.STORAGE_KEY);
  }

  set token(value: string) {
    this.localStorage.set(AccessTokenStorageService.STORAGE_KEY, value);
  }
}
