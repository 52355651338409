import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { combineLatestWith, map, shareReplay } from 'rxjs/operators';
import { HttpTrafficWatch } from './http-traffic-watch';
import { LoadingWatch } from './loading-watch';
import { NavigationWatch } from './navigation-watch';

@Injectable()
export class AppLoadingWatch implements LoadingWatch {
  private _loading$: Observable<boolean>;

  constructor(navigation: NavigationWatch, traffic: HttpTrafficWatch) {
    this._loading$ = traffic.loading$.pipe(combineLatestWith(navigation.loading$),
      map(([a, b]) => a || b),
      shareReplay(),
    );
  }

  get loading$(): Observable<boolean> {
    return this._loading$;
  }
}
