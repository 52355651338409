import { Component } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-desktop-footer',
  templateUrl: './desktop-footer.component.html',
  styleUrls: ['./desktop-footer.component.scss'],
})
export class DesktopFooterComponent {
  public currentYear() {
    return moment().format('YYYY');
  }
}
