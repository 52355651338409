import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { CourseUserRole } from '../../authentication/course-user-role';
import { Course } from '../../model';
import { BaseAuthDirective } from './base-auth.directive';

@Directive({
  selector: '[appCanEditCourse]',
})
export class CanEditCourseDirective extends BaseAuthDirective {
  private courseSubject: Subject<Course> = new ReplaySubject<Course>(1);

  @Input() public set appCanEditCourse(course: Course) {
    this.courseSubject.next(course);
  }

  constructor(templateRef: TemplateRef<any>,
              viewContainer: ViewContainerRef,
              authService: AuthService,
  ) {
    super(templateRef, viewContainer, authService);
  }

  protected canBeViewed(authService: AuthService): Observable<boolean> {
    return new CourseUserRole(
      this.courseSubject.asObservable(),
      authService.currentUserLoaded)
      .canModifyCourses;
  }
}
