import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { Assignment, Attempt } from '../model';

@Pipe({
  name: 'assignmentStudentStatus',
})
export class AssignmentStudentStatusPipe implements PipeTransform {

  transform(assignment: Assignment, attempts: Attempt[]): any {
    if (assignment.extensionExpired) {
      return 'Expired';
    }

    const attemptsLimit = assignment.currentAttemptsLimit || 'unlimited';

    const numberOfAttempts = _.chain(attempts).filter(['status', 'submitted']).size().value();

    return `Attempts done: ${numberOfAttempts}/${attemptsLimit}`;
  }
}
