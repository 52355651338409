import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[appScrollTo]'})
export class ScrollToDirective implements AfterViewInit {
  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.el.nativeElement.scrollIntoView();
  }
}
