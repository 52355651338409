import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Assignment } from '../internal';
import { Model } from '../model';

@JsonApiModelConfig({
  type: 'learnosity/educator-assignment-previews',
})
export class EducatorAssignmentPreview extends Model {
  @BelongsTo() assignment: Assignment;
  @Attribute() packet: ILearnosityItemsApiInitializationObject;
}
