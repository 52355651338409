import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cleanupHtml' })
export class CleanupHtmlPipe implements PipeTransform {
  private static readonly tagsToRemove = [/\<\/?(a|span|p|img|div|ol|ul|li)\b.*?\>/gi];

  transform(value: string): string {
    let html = value || '';

    CleanupHtmlPipe.tagsToRemove.forEach(tag => {
      html = html.replace(tag, '');
    });

    return html;
  }
}
