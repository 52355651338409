import { BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import type { Course, Exemption, User } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'enrollments',
})
export class Enrollment extends Model {
  @BelongsTo() student: User;
  @BelongsTo() course: Course;

  get courseExemption(): Exemption {
    if (!this.course || !this.student) { return; }
    return this.student.courseExemption(this.course);
  }
}
