import _ from 'lodash';
import { Subscription } from 'rxjs';

/**
 * Decorator that automatically unsubscribes from RxJS subscriptions
 * (in the component's subscriptions array) when the component is destroyed.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const UnsubscribeOnDestroy = (constructor: Function) => {
  const original = constructor.prototype.ngOnDestroy;

  constructor.prototype.ngOnDestroy = function(...args: unknown[]) {
    if (_.isArray(this.subscriptions)) {
      _.each(this.subscriptions, (s: Subscription) => s.unsubscribe());
    }

    if (_.isFunction(original)) {
      original.apply(this, ...args);
    }
  };
};
