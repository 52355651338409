import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { User } from '../../model';

@Component({
  selector: 'app-user-first-name',
  templateUrl: './user-first-name.component.html',
  styleUrls: ['./user-first-name.component.scss'],
})
export class UserFirstNameComponent implements OnInit {
  public firstName$: Observable<string>;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.firstName$ = this.authService
      .currentUser
      .pipe(
        map((user: User) => {
          if (!user) {
            return '';
          }
          return user.firstName;
        }),
        distinctUntilChanged(),
        shareReplay(1),
      );
  }
}
