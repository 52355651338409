import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Course } from './internal';
import { Model } from './model';

export type CourseGradeFormat = 'percentage' | 'points';

export interface ICourseGradesRow {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ICourseGradesRowData {
  percentage: string | number;
  points: string | number;
  max: number;
  uiReport: {
    percentage: string;
    points: string;
    tooltip: string;
  };
}

export interface ICourseGradeEntry {
  assignmentId: string;
  userId: string;
  penalizedScorePercent: number;
  penalizedScorePoints: number;
  maxScore: number;
  isEdited: boolean;
  editReason: string;
  needsManualGrading: boolean;
}

@JsonApiModelConfig({
  type: 'course-grades',
})
export class CourseGrade extends Model {
  @Attribute() packet: ICourseGradeEntry[];
  @BelongsTo() course: Course;
}
