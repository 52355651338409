import { NgForOf } from '@angular/common';
import { Directive, Host } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngForTrackById]',
})
export class NgForTrackByIdDirective<T extends { id: string | number }> {
  constructor(@Host() private ngFor: NgForOf<T>) {
    this.ngFor.ngForTrackBy = (_i: number, item: T) => item.id;
  }
};
