import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonApiDatastore, JsonApiDatastoreConfig, JsonApiModel, JsonApiQueryData, ModelType } from '@asteasolutions/angular2-jsonapi';
import { AttemptResponse } from 'app/model/attempt-response';
import { Observable } from 'rxjs';
import { Urls } from '../constants';
import {
  Access,
  Assessment,
  Assignment,
  AssignmentAggregation,
  AssignmentQuestion,
  Attempt,
  Book,
  Chapter,
  Course,
  CourseBookUpgrade,
  CourseCopy,
  CourseEngagement,
  CourseGrade,
  Enrollment,
  Exemption,
  Extension,
  ExternalResource,
  Hint,
  Institution,
  Invitation,
  LtiAssignment,
  LtiAttempt,
  LtiCourse,
  Question,
  RecentUsage,
  Revision,
  Role,
  Teaching,
  User,
  Variant,
} from '../model';
import {
  AssignmentPerQuestionScoresReport as LearnosityAssignmentPerQuestionScoresReport,
  EducatorAssignmentPreview as LearnosityEducatorAssignmentPreview,
  EducatorAssignmentReport as LearnosityEducatorAssignmentReport,
  EducatorAssignmentSessionsReport as LearnosityEducatorAssignmentSessionsReport,
  EducatorDetailsAssignmentReport as LearnosityEducatorDetailsAssignmentReport,
  LearnosityQuestion as LearnosityLearnosityQuestion,
  QuestionAuthoring as LearnosityQuestionAuthoring,
  QuestionItemPreview as LearnosityQuestionItemPreview,
  QuestionPreview as LearnosityQuestionPreview,
  QuestionScore as LearnosityQuestionScore,
  StudentAssignment as LearnosityStudentAssignment,
  StudentAssignmentReport as LearnosityStudentAssignmentReport,
  StudentDetailsAssignmentReport as LearnosityStudentDetailsAssignmentReport,
  Tag as LearnosityTag,
} from '../model/learnosity';

// Define JsonApiDatastore#query w/o @deprecated flag, as this library has been abandoned
declare module '@asteasolutions/angular2-jsonapi/services/json-api-datastore.service' {
  interface JsonApiDatastore {
    query<T extends JsonApiModel>(
      modelType: ModelType<T>,
      params?: IJsonApiQueryParams,
      headers?: HttpHeaders,
      customUrl?: string
    ): Observable<T[]>;
  }
}

export interface IJsonApiQueryParams {
  filter?: {
    like?: string;
    search?: {} | string;
    [otherFilter: string]: string | number | boolean | {};
  };
  include?: string;
  sort?: string;
  page?: {
    number?: number;
    size?: number;
  };
}

@Injectable()
@JsonApiDatastoreConfig({
  baseUrl: Urls.API_BASE,
  models: {
    accesses: Access,
    assessments: Assessment,
    assignments: Assignment,
    assignmentAggregations: AssignmentAggregation,
    assignmentQuestions: AssignmentQuestion,
    attempts: Attempt,
    attemptResponses: AttemptResponse,
    books: Book,
    canonicalInstitutions: Institution,
    chapters: Chapter,
    courses: Course,
    courseBookUpgrades: CourseBookUpgrade,
    courseGrades: CourseGrade,
    courseCopies: CourseCopy,
    courseEngagements: CourseEngagement,
    enrollments: Enrollment,
    exemptions: Exemption,
    extensions: Extension,
    ExternalResources: ExternalResource,
    hints: Hint,
    invitations: Invitation,
    'learnosity::Tags': LearnosityTag,
    'learnosity::StudentAssignments': LearnosityStudentAssignment,
    'learnosity::StudentAssignmentReports': LearnosityStudentAssignmentReport,
    'learnosity::EducatorAssignmentReports': LearnosityEducatorAssignmentReport,
    'learnosity::AssignmentPerQuestionScoresReport': LearnosityAssignmentPerQuestionScoresReport,
    'learnosity::EducatorDetailsAssignmentReports': LearnosityEducatorDetailsAssignmentReport,
    'learnosity::EducatorAssignmentSessionsReports': LearnosityEducatorAssignmentSessionsReport,
    'learnosity::StudentDetailsAssignmentReports': LearnosityStudentDetailsAssignmentReport,
    'learnosity::EducatorAssignmentPreviews': LearnosityEducatorAssignmentPreview,
    'learnosity::Questions': LearnosityLearnosityQuestion,
    'learnosity::QuestionPreviews': LearnosityQuestionPreview,
    'learnosity::QuestionItemPreviews': LearnosityQuestionItemPreview,
    'learnosity::QuestionAuthorings': LearnosityQuestionAuthoring,
    'learnosity::QuestionScores': LearnosityQuestionScore,
    ltiAttempts: LtiAttempt,
    ltiAssignments: LtiAssignment,
    ltiCourses: LtiCourse,
    questions: Question,
    recentUsages: RecentUsage,
    revisions: Revision,
    teachings: Teaching,
    users: User,
    roles: Role,
    variants: Variant,
  },
})
export class Datastore extends JsonApiDatastore {
  constructor(http: HttpClient) {
    super(http);
  }

  query<T extends JsonApiModel>(
    modelType: ModelType<T>,
    params?: IJsonApiQueryParams,
    headers?: HttpHeaders,
    customUrl?: string): Observable<T[]> {
    return super.query(modelType, params, headers, customUrl);
  }

  findAll<T extends JsonApiModel>(
    modelType: ModelType<T>,
    params?: IJsonApiQueryParams,
    headers?: HttpHeaders, customUrl?: string): Observable<JsonApiQueryData<T>> {
    return super.findAll(modelType, params, headers, customUrl);
  }
}
