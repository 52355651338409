import { Injectable } from '@angular/core';
import { JsonApiModel, ModelType } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import { Observable, of } from 'rxjs';
import { last, mergeWith, scan, switchMap } from 'rxjs/operators';
import { Datastore, IJsonApiQueryParams } from './datastore/datastore.service';

const REQUEST_PAGE_SIZE = 50;

export const MAX_PAGE_SIZE = 500;

@Injectable()
export class PaginationService {
  constructor(private datastore: Datastore) { }

  public all<T extends JsonApiModel>(
    modelType: ModelType<T>,
    options: IJsonApiQueryParams,
    pageSize = REQUEST_PAGE_SIZE,
  ) {
    return this.scan(modelType, options, pageSize).pipe(last());
  }

  public scan<T extends JsonApiModel>(
    modelType: ModelType<T>,
    options: IJsonApiQueryParams,
    pageSize = REQUEST_PAGE_SIZE,
  ) {
    return this.page(modelType, options, pageSize).pipe(
      scan((acc: T[], batch: T[]) => acc.concat(batch)),
    );
  }

  public page<T extends JsonApiModel>(
    modelType: ModelType<T>,
    options: IJsonApiQueryParams,
    pageSize = REQUEST_PAGE_SIZE,
  ) {
    const queryPage = (pageNumber: number) => {
      const paginationParams =  _.defaultsDeep({}, {number: pageNumber}, options.page, {size: pageSize});

      return this.datastore.query(modelType, {...options, page: paginationParams});
    };

    const page = (n: number): Observable<T[]> => queryPage(n).pipe(
      switchMap((items: T[]) => {
        if (items.length < pageSize) {
          return of(items);
        }
        return of(items).pipe(mergeWith(page(n + 1)));
      }),
    );

    return page(1);
  }
}
