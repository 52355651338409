export class Urls {
  static LOGIN = '/login';
  static SIGNOUT = '/sign-out';
  static CATALOG = '/fwk/catalog';
  static DASHBOARD = '/fwk/dashboard';

  static API = '/api';

  static API_BASE = Urls.API;

  static AUTH = `${Urls.API}/auth`;
  static AUTHENTICATE = `${Urls.AUTH}/authenticate`;
  static LOGOUT = `${Urls.AUTH}/logout`;

  static AUTH_CLIENT_ID = 'mesaiJeiKiiVai4soo1ahgai0Deiyeet9zeip8Eeghu5shuathieraalahg0lao9';

  static LEARNOSITY_VERSION = 'v2024.2.LTS';

  static SIGN_SELECTION_PARAMS = '/lti/selection/sign_parameters';

  static ARCHIVE_COURSE = `${Urls.API}/course_archive/archive`;

  static CREATE_REPLICA_COURSES = `${Urls.API}/course_replica/create`;

  static SURVEY_CONFIG = `${Urls.API}/survey/config`;

  static FORMULA_SUPPORT = 'https://support.flatworldknowledge.com/en/articles/8497160-using-special-keyboards-calculators-in-homework';

  // eslint-disable-next-line max-len
  static GRAPH_SUPPORT = 'https://support.flatworldknowledge.com/en/articles/9102789-how-to-use-graphs-and-graphing-tools-in-flatworld-homework';
}

export class QueryParams {
  static EMBEDDED_NAVIGATION = 'lti-embed';
  static LTI_ATTEMPT = 'lti-attempt';
  static HIDE_ASSIGNMENT_NAV = 'hide-assignment-nav';
  static CONSUMER_KEY = 'consumerKey';
  static LTIAAS_ID = 'ltiaasId';
  static CLIENT_ID = 'clientId';
  static DEPLOYMENT_ID = 'deploymentId';
  static TOOL_CONSUMER_TYPE = 'toolConsumerType';
}

export class Cookies {
  static FWK_SESSION = 'fwk_session';
  static APP_SERVER_SESSION = '_session_id';
  static NEED_AUTHENTICATION = 'need_authentication';
  static REMEMBER_USER_TOKEN = 'remember_user_token';
}

export class LocalStorage {
  static COOKIE_CONSENT = 'cookie_consented';
}
