import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TitleService } from '../../title.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent {
  @Input() opened: boolean;

  @Output() toggleSideNavigation = new EventEmitter();
  @Output() closeSideNavigation = new EventEmitter();
  @Output() logout = new EventEmitter();

  constructor(@Inject('navigateToAdminPanel') private navigateToAdminPanel: () => void,
              @Inject('navigateToDashboard') private navigateToDashboard: () => void,
              public titleService: TitleService) {
  }

  public dashboardButtonClick() {
    this.navigateToDashboard();
  }

  public menuButtonClick() {
    this.toggleSideNavigation.emit();
  }

  public openAdminPanel() {
    this.navigateToAdminPanel();
  }

  public logoutButtonClick() {
    this.logout.emit();
  }

  public buttonClicked() {
    this.closeSideNavigation.emit();
  }
}
