import { Attribute, BelongsTo, HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import _ from 'lodash';
import moment from 'moment';
import type { Access, Chapter, Course } from './internal';
import { Taggable } from './internal';

export type BookStatus = 'active' | 'draft';

const VERSION_REGEX = /\s*v\s*(\d|\.)/; // String containing "v#, v.#, v #"

@JsonApiModelConfig({
  type: 'books',
})
export class Book extends Taggable {
  @Attribute() name: string;
  @Attribute() createdAt: Date;
  @Attribute() publicationDate: Date;
  @Attribute() version: string;
  @Attribute() authors: string;
  @Attribute() questionPrefix: string;
  @Attribute() status: BookStatus;
  @Attribute() catalogUrl: string;
  @Attribute() readerUrl: string;
  @Attribute() coverUrl: string;
  @Attribute() flatworldId: string;
  @Attribute() isbnDigital: string;
  @Attribute() isbnColor: string;
  @Attribute() hasVideo: boolean;
  @Attribute() hasSolution: boolean;
  @Attribute() hasGraphing: boolean;
  @Attribute() hasExams: boolean;
  @Attribute() hasQuickCreate: boolean;
  @Attribute() hasSolutionVideo: boolean;
  @Attribute() hasExcelGrading: boolean;
  @Attribute() hasManualGrading: boolean;
  @Attribute() isCanon: boolean;
  @Attribute() copySourceId: string;
  @Attribute() hasDifficulty: boolean;

  @HasMany() chapters: Chapter[];
  @HasMany() courses: Course[];
  @HasMany() titleFamilyEditions: Book[];

  @BelongsTo() access: Access;

  isQuestionCreationPermitted(): boolean {
    return this.questionPrefix && !_.isEmpty(this.learnosityTags);
  }

  get canBeUpgraded(): boolean {
    return !_.isEmpty(this.newerEditions);
  }

  get published(): boolean {
    return this.status === 'active';
  }

  get accessible(): boolean {
    return this.access.packet;
  }

  get hasImportedChapters(): boolean {
    return !_.isEmpty(this.chapters);
  }

  get newerEditions(): Book[] {
    return _.filter(this.titleFamilyEditions, (e) => (
      e.isCanon && e.publicationDate && moment(e.publicationDate).isAfter(this.publicationDate)
    ));
  }

  get newestEdition(): Book {
    return _.first(this.newerEditions);
  }

  get nameWithVersion(): string {
    const name = _.trim(this.name);

    // Append prefixed version, if not already included in name
    return VERSION_REGEX.test(name) ? name : `${name} ${this.prefixedVersion}`;
  }

  get prefixedVersion(): string {
    const version = _.trim(this.version);
    if (!version) { return ''; }

    // Prepend version w/"v", if not already included
    return VERSION_REGEX.test(version) ? version : `v${version}`;
  }

  get isbnDigitalDigitsOnly(): string {
    return this.removeDashes(this.isbnDigital);
  }

  get isbnColorDigitsOnly(): string {
    return this.removeDashes(this.isbnColor);
  }

  private removeDashes(value: string): string {
    return (value || '').replace(/\-/g, '');
  }
}
