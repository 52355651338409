import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApplicationNavigationTypeService } from './application-navigation-type.service';

@Injectable()
export class EmbeddedResolve implements Resolve<boolean> {
  private embedded$ = new BehaviorSubject(false);

  constructor(private applicationNavigationTypeService: ApplicationNavigationTypeService) {
    this.applicationNavigationTypeService.navigationType.pipe(
      map(type => type === 'embedded'),
      take(1),
    ).subscribe(embedded => this.embedded$.next(embedded));
  }

  resolve = (): boolean => this.embedded$.getValue();
}
