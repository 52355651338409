import { Injectable } from '@angular/core';

@Injectable()
export class LocationService {
  get host(): string {
    return this.location.host;
  }

  get protocol(): string {
    return this.location.protocol;
  }

  get href(): string {
    return this.location.href;
  }

  set href(value: string) {
    this.location.href = value;
  }

  get origin(): string {
    return this.location.origin;
  }

  get root(): string {
    return `${this.protocol}//${this.host}`;
  }

  private get location(): Location {
    return window.location;
  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }

  reload() {
    this.location.reload();
  }

  replace(value: string) {
    this.location.replace(value);
  }
}
