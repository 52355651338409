import { Attribute, BelongsTo, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Assignment, IReport } from '../internal';
import { Model } from '../model';

@JsonApiModelConfig({
  type: 'learnosity/student-assignment-reports',
})
export class StudentAssignmentReport extends Model implements IReport {
  @BelongsTo() assignment: Assignment;
  @Attribute() packet: ILearnosityReportsApiInitializationObject;
}
