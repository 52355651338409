import { Component, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApplicationNavigationTypeService } from '../../application-navigation-type.service';

@Component({
  selector: 'app-application-navigation',
  templateUrl: './application-navigation.component.html',
  styleUrls: ['./application-navigation.component.scss'],
})
export class ApplicationNavigationComponent {
  @Output() logout = new EventEmitter<void>();
  public showNavigation$: Observable<boolean>;
  isSideNavOpen$ = new BehaviorSubject(false);

  constructor(navigationTypeService: ApplicationNavigationTypeService) {
    this.showNavigation$ = navigationTypeService.navigationType.pipe(
      map((type) => type !== 'embedded'),
      shareReplay());
  }

  toggleSideNav() {
    const isOpen = this.isSideNavOpen$.getValue();
    this.isSideNavOpen$.next(!isOpen);
  }

  public logoutInitiated() {
    this.logout.emit();
  }

  public onOpenedChange(e: boolean) {
    this.isSideNavOpen$.next(e);
  }
}
