import { HasMany, JsonApiModelConfig } from '@asteasolutions/angular2-jsonapi';
import type { Assignment } from './internal';
import { Model } from './model';

@JsonApiModelConfig({
  type: 'lti-assignments',
})
export class LtiAssignment extends Model {
  @HasMany() assignments: Assignment[];
}
