import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CanEditCourseDirective } from './directives/can-edit-course.directive';
import { HasEnrolledCoursesDirective } from './directives/has-enrolled-courses.directive';
import { HasTaughtCoursesDirective } from './directives/has-taught-courses.directive';
import { HoverableDirective } from './directives/hoverable.directive';
import { IsAdminPanelPermittedDirective } from './directives/is-admin-panel-permitted.directive';
import { IsEditorialPermittedDirective } from './directives/is-editorial-permitted.directive';
import { IsInEducationModeDirective } from './directives/is-in-education-mode.directive';
import { IsRoleManagementPermittedDirective } from './directives/is-role-management-permitted.directive';
import { LoadingDirective } from './directives/loading.directive';
import { MaxDirective } from './directives/max.directive';
import { MinDirective } from './directives/min.directive';
import { NgLetDirective } from './directives/ng-let.directive';
import { OnActionDirective } from './directives/on-action.directive';
import { OnAttemptRouteDirective } from './directives/on-attempt-route.directive';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { StickyShadowOnTableScrollDirective } from './directives/sticky-shadow-on-table-scroll.directive';
import { HideLearnosityQuestionTypesDirective } from './learnosity/author-embed/hide-learnosity-question-types.directive';
import { NgForTrackByIdDirective } from './directives/ng-for-track-by-id.directive';

@NgModule({
  declarations: [
    CanEditCourseDirective,
    MaxDirective,
    MinDirective,
    HasEnrolledCoursesDirective,
    HasTaughtCoursesDirective,
    HoverableDirective,
    IsAdminPanelPermittedDirective,
    IsRoleManagementPermittedDirective,
    LoadingDirective,
    NgForTrackByIdDirective,
    NgLetDirective,
    IsInEducationModeDirective,
    OnActionDirective,
    ScrollToDirective,
    IsEditorialPermittedDirective,
    StickyShadowOnTableScrollDirective,
    OnAttemptRouteDirective,
    HideLearnosityQuestionTypesDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CanEditCourseDirective,
    MaxDirective,
    MinDirective,
    IsInEducationModeDirective,
    HasEnrolledCoursesDirective,
    HasTaughtCoursesDirective,
    HoverableDirective,
    IsEditorialPermittedDirective,
    IsAdminPanelPermittedDirective,
    IsRoleManagementPermittedDirective,
    LoadingDirective,
    NgLetDirective,
    OnActionDirective,
    ScrollToDirective,
    StickyShadowOnTableScrollDirective,
    OnAttemptRouteDirective,
    HideLearnosityQuestionTypesDirective,
  ],
})
export class DirectivesModule { }
