import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationError, Router } from '@angular/router';
import _ from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { ServerErrorComponent } from '../server-error/server-error.component';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private dialog: MatDialog) {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationError))
      .subscribe(event => {
        if (event?.error?.status === '404' || event?.error?.errors?.[0]?.status === '404') {
          this.router.navigate(['404'], { queryParams: { url: event.url } });
        }
      });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        const errorCode = error.status;
        if (errorCode >= 500 && errorCode < 600) {
          this.dialog.open(ServerErrorComponent, {
            height: '350px',
            width: '750px',
            data: {
              status: errorCode,
              request,
              error,
            },
          });
        }

        return throwError(() => error);
      }),
    );
  }
}
