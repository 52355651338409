import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  public path$: Observable<string>;
  public params$: Observable<{}>;

  constructor(private route: ActivatedRoute, private router: Router) {
    const urlTree$ = this.route.queryParams.pipe(
      map(queryParams => queryParams?.url),
      map(url => this.router.parseUrl(url)),
      shareReplay(1),
    );
    this.params$ = urlTree$.pipe(
      map((urlTree) => urlTree.queryParams),
    );
    this.path$ = urlTree$.pipe(
      map(urlTree => {
        urlTree.queryParams = {};
        return urlTree.toString();
      }),
    );
  }
}
