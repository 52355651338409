import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AuthenticationFacade } from './authentication/auth.facade';
import { UnsubscribeOnDestroy } from './decorators/unsubscribe-on-destroy.decorator';
import { LoadingWatch } from './loading/loading-watch';
import { TitleService } from './title.service';

const DEFAULT_ROUTE = '/courses';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@UnsubscribeOnDestroy
export class AppComponent implements OnInit, OnDestroy {
  currentUrl: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private authFacade: AuthenticationFacade,
    private watch: LoadingWatch,
    private title: Title,
    private titleService: TitleService,
    router: Router) {
    this.subscriptions.push(router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url === '/'
          ? DEFAULT_ROUTE
          : e.url.replace('#main', '');
      }
    }));
  }

  ngOnInit() {
    this.subscriptions.push(this.watch.loading$.subscribe());
    this.subscriptions.push(this.titleService.title$.subscribe(t => this.title.setTitle(t)));
  }

  ngOnDestroy() {}

  logout() {
    this.authFacade.logout();
  }
}
