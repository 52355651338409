<div class="snackbar-container">
  <mat-icon class="snackbar-icon">
    {{ notificationIsWarning ? 'cancel' : 'check_circle' }}
  </mat-icon>

  <span matSnackBarLabel
        class="snackbar-message"
        [innerHTML]="data.message | sanitize"></span>

  <span matSnackBarActions
        class="snackbar-action">
    <button *ngIf="hasAction"
            matSnackBarAction
            mat-button
            class="action-button"
            (click)="action()">
      {{ data.actionName }}
    </button>
  </span>
</div>
