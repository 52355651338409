import { Injectable } from '@angular/core';
import { NavigationError, ResolveEnd, ResolveStart, Router } from '@angular/router';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { LoadingWatch } from './loading-watch';

@Injectable()
export class NavigationWatch extends LoadingWatch {
  public loading$: Observable<boolean>;
  private sync = false;

  constructor(router: Router) {
    super();
    this.loading$ = router.events.pipe(
      map(event => event.constructor),
      filter(constructor => _.includes([ResolveEnd, NavigationError, ResolveStart], constructor)),
      map(constructor => constructor === ResolveStart),
      distinctUntilChanged(),
      tap(isResolving => this.sync = isResolving),
      shareReplay() );
  }

  public get currentlyResolving(): boolean {
    return this.sync;
  }
}
