<h2 mat-dialog-title>Available Hints</h2>

<mat-dialog-content>
  <p class="intro">
    Here is what your professor has set up for you:
  </p>

  <p>
    Check your answer before submitting: <b>{{ assignment.showHints | hintStatus }}</b>
  </p>

  <div class="check-answers-options" *ngIf="assignment.showHints">
    <p *ngIf="!assignment.showHintsForMcq">
      Not available for multiple choice questions
    </p>
    <p *ngIf="assignment.applyHintsLimit">
      Questions will lock after <b>{{ assignment.hintsLimit }}</b> {{ assignment.hintsLimit | pluralize:'wrong answer':true }}
    </p>
  </div>

  <p>
    Read the relevant chapter: <b>{{ assignment.showChapterLink | hintStatus }}</b>
  </p>

  <p *ngIf="assignment.hasVideo">
    Watch the respective lecture: <b>{{ assignment.showVideoLink | hintStatus }}</b>
  </p>

  <p *ngIf="assignment.hasGraphing">
    Link to an online graphing calculator: <b>{{ (assignment.showGraphingCalculatorLink && assignment.hasGraphing) | hintStatus }}</b>
  </p>

  <p *ngIf="assignment.hasSolution">
    See solution of the question: <b>{{ assignment.showSolutionsDuringAttempt | hintStatus }}</b>
  </p>

  <p *ngIf="assignment.hasSolutionVideo">
    See video solution of a similar question: <b>{{ assignment.showSolutionVideoDuringAttempt | hintStatus }}</b>
  </p>

  <p *ngIf="showVaryQuestionsPerAttempt">
    Vary questions per attempt: <b>{{ assignment.randomizeQuestionsPerAttempt | hintStatus }}</b>
  </p>

  <div class="time-limit" *ngIf="assignment.hasTimeLimit">
    <mat-icon>timer</mat-icon>
    <p>
      This {{ assignment.kind }} has a <b>{{ assignment.timeLimit }}-minute</b> time limit.<br>
      It will be auto-submitted when time runs out.
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="primary">
    Got it!
  </button>
</mat-dialog-actions>
