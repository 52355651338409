import { AfterViewInit, Directive } from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
  selector: '[appGoogleTagsScript]',
})
export class GoogleTagsScriptDirective implements AfterViewInit {
  ngAfterViewInit() {
    const googleTagID = environment.googleTagID;
    if (!googleTagID) {
      return;
    }

    const googleScript = document.createElement('script');
    googleScript.type = 'text/javascript';
    googleScript.text = this.googleTagManagerSnippet(googleTagID);
    document.head.appendChild(googleScript);

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${googleTagID}`);
    iframe.setAttribute('height', '0');
    iframe.setAttribute('width', '0');
    iframe.setAttribute('style', 'display:none;visibility:hidden');
    noscript.appendChild(iframe);

    const body = document.body;
    body.insertBefore(noscript, body.firstChild);
  }

  private googleTagManagerSnippet(googleTagId: string): string {
    // eslint-disable-next-line max-len
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${googleTagId}');`;
  }
}
